<template>
  <!-- 弹框 -->
  <el-dialog :title="isPay ? '支付' : type == 1 ? '助力冲榜' : '上火爆区'" :visible="outerVisible" width="30%" top="32vh" center
    class="addspotdialog" :showClose="false" @open="getCheckHotSport">
    <div v-if="type == 1 && !isPay" style="display: flex; flex-direction: column; justify-content: center">
      <p class="diatop">冲榜方式：</p>
      <div class="dialogbox flex">
        <div class="innerbox flex column" @click="getChangeItem(1)" :class="{ selected: choose === 1 }">
          <div class="flex flex1">冲榜首</div>
          <div class="flex flex1 column">
            <p>{{ hotData.toTop }}助力值</p>
            <p>金额¥{{ hotData.toTop * multiple }}</p>
          </div>
        </div>
        <div class="innerbox flex column" @click="getChangeItem(2)" :class="{ selected: choose === 2 }">
          <div class="flex flex1">上升一名</div>
          <div class="flex flex1 column">
            <p>{{ hotData.beyondPrevious }}助力值</p>
            <p>金额¥{{ hotData.beyondPrevious * multiple }}</p>
          </div>
        </div>
        <div class="innerbox flex column" @click="getChangeItem(3)" :class="{ selected: choose === 3 }">
          <div class="flex flex1">
            <input typeName="text" placeholder="自定义" v-model="customNum" @blur="getChangeItem(3)" />
          </div>
          <div class="flex flex1 column">
            <p>{{ customNum }}助力值</p>
            <p>金额¥{{ customNum }}</p>
          </div>
        </div>
      </div>
      <div class="tip allcenter">
        <span style="display: block;margin-top: 14px;">温馨提示：给您喜欢的服务器助力冲榜，即可提升本服的排名。每1助力值为
          1个三千币或1人民币，给连发信息助力时，助力金额将乘以精品连发数量。</span>
      </div>
    </div>

    <span v-if="!isPay && type != 3" class="dialog-footer">
      <el-button plain @click="setShow(false)" class="diaoff">取 消</el-button>
      <el-button type="primary" @click="gopok" class="diaon" :disabled="choose==null||cost==0">提交</el-button>
    </span>

    <Pay v-if="isPay || type == 3 || close == true" @handlePay="handlePay" :type="type" :paying="paying" :useSqb="cost" :id="id"
      @closePay="closePay" @payOk="paying = 2"></Pay>
  </el-dialog>
</template>

<script>
import api from "@/api/api.js";
import Pay from "./Pay.vue";
export default {
  components: {
    Pay,
  },
  props: {
    loginOk:{
      default: 0,
      type: Number,
    },
    id: {
      default: 0,
      type: Number,
    },
    multiple: {
      default: 1,
      type: Number,
    },
    type: {
      default: null,
      type: Number,
    },
    whatad: {
      type: Number,
    },
  },
  data() {
    return {
      close: false,
      hotData: {},
      isPay: false,
      outerVisible: false,
      showType: 1,
      sqb: 0,
      paying: 1,
      choose: null,
      customNum: "",
      hotVisible: "",
      cost: "",
      paySuccess: "",
      hidDialog:null,
    };
  },
  // created(){
  //   this.value=this.options
  // },

  created() {
  },
  mounted(){
  },
  beforeDestroy() { 
  },
  methods: {
    setShow(val) {
      this.cost = 500
      this.customNum = ''
      this.choose = null
      this.outerVisible = val;
    },
    // 关闭支付页
    closePay() {
      // 上火爆区
      if (this.type == 3) {
        this.isPay = false;
        this.$emit('closeDialog')
        // this.type = 1 
        this.outerVisible = false;
        // this.$refs.myDialog.setShow(false);
      } else {
        this.isPay = false;
      }
    },
    getChangeItem(val) {
      if(this.type == 1){
          let data = {
              id: this.id,
            };
            api.getCheckHotSport(data).then((res) => {
              this.hotData = res;
            });
        }
      this.choose = val;
      switch (val) {
        case 1:
          this.cost = this.calculatePrice(
            this.hotData.toTopCost,
            this.multiple
          );
          break;
        case 2:
          this.cost = this.calculatePrice(
            this.hotData.beyondPreviousCost,
            this.multiple
          );
          break;
        case 3:
          // this.cost = this.calculatePrice(this.customNum, this.multiple);
          this.cost = Number(this.customNum)
          break;
        default:
          break;
      }
    },

    calculatePrice(a, b) {
      return a * b;
    },
    getCheckHotSport() {

      let data = {
        id: this.id,
      };
      if (this.type == 1) {
        api.getCheckHotSport(data).then((res) => {
          if(res) this.hotData = res;
        });
      }
    },

    gopok() {
      this.isPay = true;
      if (this.type == 2) {
        this.cost = this.calculatePrice(500, 1);
      }
    },
    handlePay(val) {
      const data = {
        id: this.id,
        addHotSport: this.cost,
      };
      switch (val) {
        case 1:
          api[this.type == 1 ? "getAddHotSport" : "getGoHotArea"](data).then(
            (res) => {
              if (!res) {

                this.paying = 3;
              } else {
                this.isPay = true;
                this.paying = 2;
              }
            }
          );
          break;
        case 2:
          this.isPay = false;
          break;
        case 3:
          this.$emit("hiddenDialog", false);
          window.location.reload();
          this.outerVisible = false;
          break;
        default:
          break;
      }
    },
  },
  watch: {
    'type': {
      handler(newval, oldval) {
        if (newval == 3) {
          this.cost = 500
        }
      }
    },
    'hotData': {
      handler(newval, oldval) {
        if (!newval) {
          var timers = setInterval(() => {
            let data = {
              id: this.id,
            };
            api.getCheckHotSport(data).then((res) => {
              this.hotData = res;
            });
          }, 200);
        }else{
          clearInterval(timers)
        }
      },
      immediate:true
    },
    'loginOk':{
      handler(newval){
        if(newval == 1 && this.type != 3){
          let data = {
              id: this.id,
            };
            api.getCheckHotSport(data).then((res) => {
              this.hotData = res;
            });
        }
      }
    },
    'customNum':{
      handler(newval){
        if(newval) this.cost = parseInt(newval)
      }
    }
  }
};
</script>


<style lang="scss" scoped>
@import "@/common/Popup.scss";
::v-deep .el-input__suffix{
  top: -3px;
}
.dialog-footer {
  display: flex;
  flex-direction: row;
  margin-top: 50px;
  justify-content: center;
}
</style>