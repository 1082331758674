<template>
  <div class="" style="margin-top: 13px; overflow: hidden; background-color: #fff;width: 890px;">
    <!-- <div class="table-name">
      <h2>今日开服</h2>
      <p>({{ getToDayTime() }})</p>
    </div> -->
    <div class="tablecontent" style="position: relative">
      <Table :alltable.sync="list" :freetable.sync="freelist" :pic="adimg4" typeName="今日开服" :tabStyle="{}" :imgShow="true">
      </Table>
    </div>

  </div>
</template>

<script>
import api from "@/api/api.js";
import Table from "@/components/Table/index.vue"
export default {
  props: ["pic"],
  data() {
    return {
      adimg4: [],
      isAccessAll: false,
      isExecute: false,
      gameTypeId: null,
      mygoodbutton: 0,
      list: [],
      freelist: [],
      // 分页
      current: 1, // 当前页
      size: Math.ceil(document.documentElement.clientHeight / 43) || 20, // 每页总条数
    };
  },
  components: {
    Table
  },
  watch: {
    mygoodbutton: {
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.mygoodbutton = newVal;
        }
      },
      deep: true,
    },
    // pic: {
    //   handler(newval) {
    //     if (newval.length == 0) {
    //       setTimeout(() => {
    //         this.$emit('getImg')
    //       }, 1500);
    //     }
    //   }
    // }
  },
  created() {
    // this.$emit('getImg')
    this.getList();
    // this.gettodayfree();
   
  },
  mounted() {
    // this.$emit('getImg')
    // this.getImg()
  },
  methods: {

    async getList() {
      if (this.isAccessAll) return true;
      let data = {
        gameTypeId: this.gameTypeId,
        current: this.current,
        size: this.size,
      };
      try {
        // console.log(this.list,'拿到今日开服的数据')
        const result = await api.getToday();
        this.list.push(...this.handleListItem(result));
        this.isAccessAll = true;
        // console.log(this.list,'拿到今日开服的数据')
        return false;
      } catch (error) {
        console.error(error,'今日开服列表');
        return false;
      }
    },
    async gettodayfree() {
      if (this.isAccessAll) return true;
      let data = {
        gameTypeId: this.gameTypeId,
        current: this.current,
        size: this.size,
      };
      try {
        // // console.log(this.list,'拿到免费广告的数据接上')
        // const result = await api.getFutureOpen();
        const result = await api.getFreeAd();

        // console.log(result,'ddddddddd')
        const todaytime = this.getToDayTime();
        if (result) {
          const freelist = result.filter((item) => item.openTime.substring(0, 10) === todaytime);
          // console.log(result,'过滤后的数据')
          this.freelist.push(...this.handleListItem(freelist));
        } else {
          this.freelist = [];
        }
        // this.freelist.push(...result);
        // console.log(this.freelist,'dddd')
        // this.result.filter((item) => item.openTime == getToDayTime);
        this.isAccessAll = true;
        return false;
      } catch (error) {
        console.error(error);
        return false;
      }
    },
    // 对列表数据进行处理
    handleListItem(arr = []) {
      return arr.map((item) => {
        if (item.gameTypeId == 1) {
          item.gameTypeId = item.gameTypeId + 3
        } else if (item.gameTypeId == 2) {
          item.gameTypeId = item.gameTypeId + 0
        } else if (item.gameTypeId == 3) {
          item.gameTypeId = 3
        } else if (item.gameTypeId == 4) {
          item.gameTypeId = 1
        }
        return {
          ...item,
          // openTime: item.openTime > getToDayTime() ? '已开服' :item.openTime < getToDayTime() ? '敬请期待':'',
          openTime: item.openTime.substring(10, 16),
        };
      });
    },

    getToDayTime() {
      let date = new Date();
      let nowMonth = date.getMonth() + 1;
      let strDate = date.getDate();
      let seperator = "-";
      // 对月份进行处理，1-9月在前面添加一个“0”
      if (nowMonth >= 1 && nowMonth <= 9) {
        nowMonth = "0" + nowMonth;
      }

      // 对月份进行处理，1-9号在前面添加一个“0”
      if (strDate >= 0 && strDate <= 9) {
        strDate = "0" + strDate;
      }
      // 最后拼接字符串，得到一个格式为(yyyy-MM-dd)的日期
      let nowDate =
        date.getFullYear() + seperator + nowMonth + seperator + strDate;
      return nowDate;
    },
  },
};
</script>

<style lang="scss" scoped>
.table-name {
  display: flex;
  height: 85px;
  width: 100%;
  justify-content: center;
  align-items: center;

  h2 {
    font-size: 20px;
    font-weight: 600 !important;
    color: #000;
    letter-spacing: 14px;
  }

  p {
    font-size: 20px;
    color: #000;
    margin-left: 10px;
    font-weight: 600 !important;
    letter-spacing: 1px;
  }
}
</style>
  


