<template>
  <div class="" ref="box" style=" overflow: hidden; background-color: #fff">
    <div style="width: 890px; margin: 0 auto;  margin-top: 13px;">
      <!-- <Table :alltable.sync="list" typeName="开服预告" :tabStyle="{}"></Table> -->
      <div class="app-content" :class="tableType">
        <div class="top">
          <span class="row-item row-item1" style="margin-right: 73px;width:142px;margin-left:30px">服务器名</span>
          <span class="row-item row-item2" style="width:143px">开测时间</span>
          <span class="row-item row-item3 " style="margin-right: 52px;margin-left:83px">版本简介</span>
          <span class="row-item row-item4" style="margin-left: 191px;">距开测</span>
        </div>
        <div class="nothingdata" v-if="list.length == 0">
          <img src="@/assets/images/no result.png" alt="" />
          <p>暂无数据~</p>
        </div>
        <div class="conten" :style="{
          backgroundColor: conBoxStyle.bgC,
          color: conBoxStyle.color,
        }">
          <div v-for="(item, index) in bigtable" :key="index" class="eachLine"
            @click="lineClick(item.btnType, item.webUrl, item.id,item.isSubPage)">
            <div class="row" v-if="!item.contentType" :class="item.classObj?.row">
              <div class="row-item row-item1" style="width: 195px;">
                <div class="yg" v-if="item.openTime.substring(0, 10) == getafterTime()"
                  style="width: 24px; height: 24px;line-height: 20px;color: #E34A4A;border: 2px solid #E34A4A;text-align: center;border-radius: 3px;margin-right: 10px;">
                  明</div>
                <div class="yg" v-else style="width: 24px; height: 24px;line-height: 20px;color: rgb(255, 114, 37); 
                  border: 2px solid rgb(255, 114, 37);text-align: center;border-radius: 3px;margin-right: 10px;">
                  预</div>
                <div class="server-name" style="margin-right: 4px;">
                  {{ item.serverName }}
                  <!-- <a :href="item.webUrl" target="_blank"> </a> -->
                </div>
                <!-- <div class="today">今</div> -->

                <div class="number" v-if="item.multipleTotal > 1">
                  x
                  <span>
                    {{ item.multipleTotal }}
                  </span>
                </div>
                <div class="jp" style="margin-left: 8px;">精</div>
              </div>
              <div class="row-item row-item2" style="text-indent: 7px;">
                {{ item.openTime }}
              </div>
              <div class="row-item row-item3" style="padding-left:50px;width: 325px;position: relative; ">
                <div class="game-type" :class="item.isOpenHot" :style="{
                  backgroundColor: item.gameTypes.bgC,
                  color: item.gameTypes.color,
                }">
                  {{ item.gameTypes.name }}
                </div>
                <span>
                  {{ item.versionDescribe }}
                </span>
              </div>
              <div class="row-item row-item4" style="margin-left:50px;width: 60px;">
                {{ item.counttime }}
              </div>
              <!-- <div class="row-item row-item5" style="width: 180px;"> -->
              <!-- <div class="hot-spot">
                  <img src="@/assets/images/fire.png" alt="" />
                  <span>{{ item.totalSpot || 0 }}</span>
                </div> -->

              <!-- <el-button class="pay" @click.stop="
                  goHelp(item.btnType, item.webUrl, item.id, item.multipleTotal)
                ">
                  {{ item.btnType }}
                </el-button> -->
              <!-- </div> -->
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/api/api.js";
const btnTypes = ["去助力", "上火爆区", "点击进入"];
const gameTypes = [
  {
    name: "三端",
    bgC: "#2094ff",
  },
  {
    name: "源码",
    bgC: "#00D2B9",
  },
  {
    name: "手游",
    bgC: "#2AB91E",
  },
  {
    name: "端游",
    bgC: "#FFA49E",
  },
];

export default {
  data() {
    let conBoxStyle = {
      bgC: "#FFFFFF",
      color: "#000000",
    };
    let tableType = "";
    switch (this.typeName) {
      case "精品置顶":
        tableType = "table-1";
        break;
      case "今日开服":
        tableType = "table-2";
        break;
      case "已开推荐":
        tableType = "table-3";
        break;
      case "开服预告":
        tableType = "table-1";
        break;
      default:
        break;
    }
    return {
      tomorrow: null,
      typeName: '开服预告',
      isAccessAll: false,
      isExecute: false,
      gameTypeId: null,
      tableType,
      multiple: 1,
      id: 0,
      conBoxStyle,
      isOpenHot: 1,
      hidDialog: null,
      loginOk2: 0,
      list: [
      ],
      // 分页
      current: 1, // 当前页
      size: Math.ceil(document.documentElement.clientHeight / 43) || 20, // 每页总条数
    };
  },
  components: {
    // Table: (resolve) => {
    //   require(["@/components/Table/index.vue"], resolve);
    // },
  },
  created() {
    this.getList();
    this.bigtable = this.handleList(this.list);

  },
  mounted() {
  },

  methods: {
    // 明天
    getafterTime() {
      let date = new Date();
      let nowMonth = date.getMonth() + 1;
      // 日期-1 昨天 +1 明天 不 今天
      let strDate = date.getDate() + 1;
      let seperator = "-";
      // 对月份进行处理，1-9月在前面添加一个“0”
      if (nowMonth >= 1 && nowMonth <= 9) {
        nowMonth = "0" + nowMonth;
      }

      // 对月份进行处理，1-9号在前面添加一个“0”
      if (strDate >= 0 && strDate <= 9) {
        strDate = "0" + strDate;
      }
      // 最后拼接字符串，得到一个格式为(yyyy-MM-dd)的日期
      // let nowDate =
      //   date.getFullYear() + seperator + nowMonth + seperator + strDate;
      let nowDate =
        date.getFullYear() + seperator + nowMonth + seperator + strDate;

      return nowDate;
    },
    goHelp(v, url, id) {
      window.open(url);
      this.adHitCount(id);
    },
    async getList() {

      if (this.isAccessAll) return true;
      let data = {
        // gameTypeId: this.gameTypeId,
        current: this.current,
        size: this.size,
      };
      try {
        const result = await api.getFutureOpen();
        this.list.push(...this.handleListItem(result));
        this.isAccessAll = true;
        return false;
      } catch (error) {
        console.error(error);
        return false;
      }
    },
    // 对列表数据进行处理
    handleListItem(array = []) {
      return array.map((item) => {
        const date = new Date(item.openTime).getTime();
        let openType = item.openTime.substring(0, 10);
        // console.log(openType,'开测时间')
        const currentTime = new Date();
        const timeDifference = date-currentTime.getTime();
    const seconds = Math.floor(timeDifference / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
        let counttime
        if (days >= 1) {
          counttime = `${days} 天`;
        } else if (hours >= 1) {
          counttime = `${hours} 小时`;
        } else {
          counttime = `1 小时内`;
        }
        // if(new Date().getTime()>date) openType = "已开服";
        // if (item.openTime === "1000-01-01 00:00:00") openType = "已开服";
        // if (item.openTime === "3000-01-01 00:00:00") openType = "敬请期待";
        if (item.gameTypeId == 1) {
          item.gameTypeId = item.gameTypeId + 3
        } else if (item.gameTypeId == 2) {
          item.gameTypeId = item.gameTypeId + 0
        } else if (item.gameTypeId == 3) {
          item.gameTypeId = 3
        } else if (item.gameTypeId == 4) {
          item.gameTypeId = 1
        }
        return {
          ...item,
          openTime: openType,
          counttime:counttime,
        };
      });
    },
    handleList(val) {
      let list_ = val.filter((item) => item); // 去除手游类型;

      list_ = this.handleExtendList(list_);
      list_.map((item, index) => {
        // 游戏标签
        item.gameTypes = {
          ...gameTypes[item.gameTypeId - 1],
          // color: item.labelColor,
          // bgC: item.labelBackgroundColor || gameTypes[item.gameTypeId - 1].bgC,
        };

        // 按钮
        item.btnType = btnTypes[2];
        if (this.tableType == "table-1") {
          item.btnType = btnTypes[0];
        }
        if (this.tableType == "table-2" && item.hotArea === 0) {
          item.btnType = btnTypes[1];
        }
        if (this.tableType == "table-1") {
          const time = item.openTime && item.openTime.split(" ");
          item.openTime = time?.[0];
        }
        return item;
      });
      return list_;
    },

    handleExtendList(list) {
      let arr = [];
      // 扩展条数
      list.map((item, index) => {
        if (item.contentType == undefined || item.contentType == "") {
          let len =
            this.tableType == "table-1" ? item.topNumber : item.multipleTotal;
          Array.from({ length: len || 1 }, (_, idx) => {
            arr.push({
              ...this.handleTabItemStyle(item, index, idx),
              ...item,
            });
          });
        } else {
          arr.push({
            ...item,
          });
        }
      });
      return arr;
    },
    handleTabItemStyle(item, index, idx) {
      let classObj = {
        row: "",
      };
      let skin = {};
      if (idx > item.topNumber - 1) {
        classObj.row = classObj.row + " showJP";
      }

      if (this.tableType == "table-2" && item.hotArea === 0) {
        classObj.row = classObj.row + " chuanggui";
      }
      if (idx <= item.skinNumber - 1 && item.hotArea !== 0) {
        skin = {
          bgC: item.backgroundColor || "#fafabb", //  item.backgroundColor ,
          color: item.fontColor || "#ff6d1a", //item.fontColor,
        };
      }
      return {
        skin,
        classObj,
      };
    },
    // 整行点击跳转链接
    lineClick(values, url, id,isSubPage) {
      this.adHitCount(id)
      if(isSubPage==1){
        this.$router.push({
          name: "AdDetails", params: {
            id:id,
          },
        });
      }else{
        window.open(url)
      }
    },
    // 广告点击量统计
    async adHitCount(id) {
      const res = await api.adHitCount({ id })
    },
    hiddenDialog() {
      this.$emit("getList", true);
    },
  },
  watch: {
    list(val) {
      this.bigtable = this.handleList(val);
    },
  }
};
</script>

<style lang="scss" scoped>
// 引入弹窗样式 只有今日开服和开服预告两个表格有
@import "@/common/Popup.scss";
// @import "@/common/table.scss";

/* 弹窗助力按钮统一样式放公告样式里了！ */
// 只有点击进入的表格间距和其他不一样  已开和推荐 所以要单独写
// .game-type {

//     position: absolute;
//     margin-left: 2px;
// }
.app-content {
  // min-height: 424px;
  // width: 1100px;

  width: 890px;
  margin: 0 auto;
  padding-bottom: 0;
  background-color: #fff;

  .top {
    width: 100%;
    justify-content: flex-start;
    padding: 0 22px;
    display: flex;
    background: #F2F2F2;
    border: 1px solid #D7D7D7;

    .row-item {
      line-height: 41px;
      color: #404040;
      // font-weight: 550;
      font-size: 16px;
      letter-spacing: 3px;
      height: 41px;
    }

    &:nth-child(0) {
      .row-item5 {
        padding-left: 104px;
      }
    }

  }

  .eachLine {
    .row {
      &:hover {
        background: #fcfcfc !important;
        cursor: pointer;
      }
    }

  }

  .conten {
    border: 1px solid rgba(232, 232, 237, 0.801);
    font-size: 14px;
    background-color: #fff;
    text-align: left;
    font-weight: 500;
    letter-spacing: 1px;
    color: #404040;
    // padding-bottom: 64px;

    .row {
      display: flex;
      padding: 2px 12px 0px 22px;
      width: 100%;
      border-bottom: 1px solid rgba(232, 232, 237, 0.801);
      height: 33px;
      justify-content: space-between;
      position: relative;
    }

    .item-pic {
      width: 100%;
      // border:1px solid pink;
      // background: pink;
      // padding: 15px 23px 0;
      // background-color: #fff;
      // margin-top: -2px;
      margin-top: 22px;
      // margin-bottom: 15px;
      position: relative;

      a img {
        width: 1100px;
        height: 110px;
      }
    }

    .tag-box {
      width: 100%;
      height: 0px;
      position: relative;

      .tag1 {
        width: 41px;
        height: 106px;
        left: -50px;
        position: absolute;
        background-image: url(@/assets/images/火爆.png);
        background-size: contain;
        background-repeat: no-repeat;
        pointer-events: none;
      }

      .tag2 {
        width: 41px;
        height: 106px;
        left: -50px;
        position: absolute;
        background-image: url(@/assets/images/free.png);
        background-size: contain;
        background-repeat: no-repeat;
        pointer-events: none;
      }
    }
  }

  .row-item {
    display: flex;
    align-items: center;
    white-space: nowrap;
    flex-shrink: 0;
    position: relative;
    padding-left: 3px;

    span {
      flex-shrink: 0;
      max-width: 196px !important;
      overflow: hidden;
    }
  }
}

.table-1 {

  margin-bottom: 15px;

  .row-item6 {
    padding-left: 100px;
  }

  .conten {
    .row {}
  }

  .row-item {
    font-weight: normal;

    span {
      max-width: 204px;
    }

    .today {
      display: none;
    }

    .today2 {
      display: none;
    }

    .jp {
      position: absolute;
      left: -30px;
    }

    .game-type {
      border: 1px solid red;
      position: absolute;
      left: -44px;
    }

    .number {
      margin-left: 5px;
    }

    .hot-spot {
      width: 95px;

      span {
        width: 72px;
      }
    }
  }

  .row-item1 {

    width: 120px;
    margin-left: 30px;
  }

  .row-item2 {

    justify-content: flex-start;
    width: 140px;
    padding-left: 20px;
    // text-indent: 22px;

    span {
      width: 5;
    }
  }

  .row-item3 {
    // border:1px solid red;
    margin-left: 20px;
    width: 190px;
  }

  .row-item4 {
    border: 1px solid rd;
    width: 199px;
  }

  .row-item5 {
    width: 180px;
    // padding-left: 104px;
  }
}

.table-2 {
  // border:1px solid pink;
  margin-bottom: 15px;

  .row-item6 {
    padding-left: 100px;
  }

  .conten {
    .row {}
  }

  .row-item {
    font-weight: normal;

    span {
      max-width: 204px;
    }

    .today {
      display: none;
    }

    .today2 {
      display: none;
    }

    .jp {
      position: absolute;
      left: -30px;
    }

    .game-type {

      position: absolute;
      left: -40px;
    }

    .number {
      margin-left: 5px;
    }

    .hot-spot {
      width: 95px;

      span {
        width: 72px;
      }
    }
  }

  .row-item1 {
    width: 120px;
    margin-left: 30px;
  }

  .row-item2 {
    justify-content: flex-start;
    width: 140px;
    padding-left: 20px;

    // text-indent: 22px;

    span {
      width: 5;
    }
  }

  .row-item3 {
    // border:1px solid red;
    margin-left: 20px;
    width: 190px;
  }

  .row-item4 {
    width: 199px;
  }

  .row-item5 {
    width: 180px;
    // padding-left: 104px;
  }
}

.table-3 {
  padding-bottom: 70px;

  .conten {
    .row {
      // font-size: 14px;
    }
  }

  .row-item {
    .today {
      display: none;
    }

    .today2 {
      display: none;
    }

    .jp {
      position: absolute;
      left: -30px;
    }

    .game-type {
      border: 1px solid red;
      position: absolute;
      left: -44px;
    }

    .hot-spot {
      display: none;
    }
  }

  .row-item1 {
    width: 120px;
    margin-left: 30px;

  }

  .row-item2 {
    border: 1px solid red;
    width: 170px;
  }

  .row-item3 {
    width: 190px;
  }

  .row-item4 {
    width: 190px;
  }

  .row-item5 {
    width: 90px;
  }

  .top .row-item5 {
    margin-right: -9px;
    margin-left: 7px;
  }
}

.table-4 {
  padding-bottom: 70px;

  .conten {
    .row {
      font-size: 14px;
    }
  }

  .row-item {
    span {
      max-width: 180px;
    }

    .today {
      display: none;
    }

    .today2 {
      display: none;
    }

    .jp {
      position: absolute;
      left: -30px;
    }

    .game-type {
      position: absolute;
      left: -40px;
    }
  }

  .row-item1 {
    width: 120px;
    margin-left: 30px;
  }

  .row-item2 {
    border: 1px solid red;
    width: 170px;
  }

  .row-item3 {
    width: 190px;
  }

  .row-item4 {
    width: 190px;
  }

  .row-item5 {
    width: 175px;
  }
}

// 常规推荐样式
.app-content .conten .chuanggui {
  color: #a3a1a1;
  font-size: 14px;

  .row-item {
    span {
      max-width: 180px;
    }
  }

  .today {

    // color: #777777;
    // border-color: #a3a1a1;
    color: #fc5612;
    border-radius: 4px;
    border: 2px solid #fc5612;
  }

  .today2 {
    width: 23px;
    height: 23px;
    font-size: 12px;
    // color: #777777;
    // border-color: #a3a1a1;
    color: #a3a1a1;
    border-radius: 4px;
    border: 2px solid #a3a1a1;
  }

  .number {
    display: none;
  }

  .jp {
    display: none;
  }

  .game-type {
    display: none;
  }

  .hot-spot {
    display: none;
  }
}

// 皮肤
// .app-content .conten .skin1 {
//   color: #ff6d1a;
//   background-color: #fafabb;
//   // font-size: 14px;
//   .hot-spot {
//     color: #ff6d1a;
//   }
// }

// 精品图标
.app-content .conten .showJP {
  .jp {
    display: none;
  }
}
</style>

  
