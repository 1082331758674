<template>
  <div class="outer flex column" id="outer">
    <!-- 这是背景轮播图 -->
    <div class="leftArrow flex allcenter" @click="arrowClick('left')">
      <img src="@/assets/newpic/left.png" style="width:16px;height:23px;" />
    </div>
    <div class="big">
      <el-carousel :interval="5000" height="880px" arrow="never" ref="cardShow"  style="position:relative; background-color: #fff;">
      <el-carousel-item v-for="item in lblist1" :key="item.id">
        <el-button @click.stop="goAD(item.id, 6)"
          style="z-index:90;width:130px;height:42px;
        ;position:absolute;top:310px;left:21%;font-weight: bold;font-size:18px;color:#FFFFFF;background:#FF7226;border-radius:30px">
          查看详情</el-button>
        <img :src="item.url | montageImgUrl(that)" alt="" style="  width: 100%;object-fit: cover;cursor:pointer"
          @click.stop="goCarousel(item.linkUrl)">
      </el-carousel-item>
    </el-carousel>
    </div>
    <div class="rightArrow flex allcenter" @click="arrowClick('right')">
      <img style="width:16px;height:23px;" src="@/assets/newpic/right.png" />
    </div>
    <!-- 内容部分 -->
   <div style="width: 100%; background-color: #fff; padding-bottom: 50px;">
    <div class="bx column" style="">
      <div style="position: absolute;z-index: 88; margin-top:-457px;">
        <div class="headtop flex " style="background:#FFFFFF">
          <div class="rightTop flex ">
            <div class="leftbar flex column">
              <div class="theleftbar flex acenter" v-for="(item, index) in newrlist" :key="index"
                :class="{ newactive2: index == current_rpctive }" @click="gotype4(item.id, index, item)" style="background: #FFFFFF;width:245px;;height:66px; font-size: 14px;;cursor: pointer;
                border-bottom:1px solid #E0E0E0;color:#333333;
                ">
                <div class="flex acenter" style="height:66px;">
                  <img :src="item.url | montageImgUrl(that)" alt=""
                    style="height:48px;width:48px;margin-left:15px;margin-right:9px; border-radius: 10px;">
                  <div class="flex column">
                    <div style="width: 160px;display:-webkit-box;overflow: hidden;text-overflow: ellipsis;
word-wrap: break-word;-webkit-line-clamp: 1;-webkit-box-orient: vertical;"> {{ item.name }} </div>
                    <div class="flex" style="margin-top:7px">
                      <img src="@/assets/newpic/time.png" alt="" style="width:18px;height:18px;margin-right:7px">
                      <div style="color:#37B389;"> {{ item.time }}</div>
                    </div>
                  </div>
                </div>
                <img src="@/assets/newpic/sj.png" v-if="index == current_rpctive" alt=""
                  style="height:17px;width:16px;position: absolute;left:244px">
              </div>
            </div>
            <img :src="photoAds?.img | montageImgUrl(that)" alt="" style="height:330px;width:504px;"
              @click.stop="goCarousel(photoAds.linkUrl)">
          </div>
          <div class="leftTop flex column">
            <div class="flex  acenter" style="border-bottom:1px solid #E0E0E0;height: 40px;width: 100%;">
              <div class="flex allcenter" v-for="(item, index) in newlist" :key="index"
                :class="{ newactive: index == current_newactive }" @click="gotype3(item.id, index)"
                style="height: 40px; font-size:18px;margin-right:40px;cursor: pointer;color:black;">
                {{ item.name }}

              </div>
              <div style="color: #7F7F7F;margin-left: auto;font-size: 14px;cursor:pointer;z-index:99"
                class="flex acenter" @click="gonew(5)">
                <span style="cursor:pointer;z-index:99">更多</span>
                <img src="@/assets/newpic/more.png" alt="" style="height:15px;width:21px ;">
              </div>

            </div>
            <!-- 新闻 -->
            <div class="flex acenter" v-for="(item, index) in newmsg" :key="index" @click="goAD(item.id, 5, item.categoryId)"
              :style="item.id == 5 ? '' : 'border-bottom:1px solid #E0E0E0;'" style="
              height:56px;color:#333333;cursor:pointer;">
              <div class="flex allcenter newcss"
                :class="item.categoryId == 21 ? 'green1' : item.categoryId == 22 ? 'orange1' : 'red1'">
                {{ item.categoryId == 21 ? '新闻' : item.categoryId == 22 ? '公告' : item.categoryId == 23 ? '活动' : '推荐' }}</div>
              <div style="display:-webkit-box;overflow: hidden;text-overflow: ellipsis;
word-wrap: break-word;-webkit-line-clamp: 1;-webkit-box-orient: vertical;  width: 290px;"> {{ item.title }}</div>
              <!-- .substring(5, 11) -->
              <div style="margin-left:auto;color:#AAAAAA">{{ item?.createTime.substring(5, 11) }}</div>
            </div>
          </div>
        </div>
        <div class="flex">
          <img :src="bighf?.imageUrl | montageImgUrl(that)" alt="" @click.stop="goCarousel(bighf.linkUrl)"
            style="height:100px;width:1260px ;margin-top:24px;cursor:pointer; border-radius: 6px;">
        </div>
      </div>
      <div class="centerPart flex" style="padding-top: 30px;">
        <div class="leftpart">
          <el-carousel height="548px" style="border-radius:6px" class="notbig">
            <el-carousel-item v-for="item in lblist" :key="item.id">
              <img :src="item.url | montageImgUrl(that)" alt="" style="cursor:pointer;"
                @click.stop="goCarousel(item.linkUrl)">
            </el-carousel-item>
          </el-carousel>
          <!-- 技术服务 -->
          <div style="margin-top: 24px;">
            <div class="box flex1" style="height:553px;">
              <div class="Noticinnerbox" style="">
                <div class="flex acenter nhead">
                  <span style="color:#404040;font-size: 18px;font-weight: bold;">GM专区</span>
                  <span style="color: #7F7F7F;margin-left: auto;font-size: 12px;cursor:pointer"
                    @click="gont(3)">更多></span>
                </div>
                <div style="width: 362px;flex-wrap: wrap;justify-content: space-between;" class="flex">
                  <div class="flex" v-for="(item, index) in lists2" :key="index" style="">
                    <div class="flex column" style="width:168px;margin-bottom: 25px;cursor:pointer"
                      @click="goAD(item.id, 3)">
                      <img :src="item.url | montageImgUrl(that)" alt=""
                        style="height:100px;width:170px;margin-bottom:9px;border-radius: 4px">
                      <div style="display:-webkit-box;overflow: hidden;text-overflow: ellipsis;
word-wrap: break-word;-webkit-line-clamp: 2;-webkit-box-orient: vertical;  width: 178px;">{{ item.name }}</div>
                    </div>
                  </div>
                </div>
                <div class="Notic flex" v-for="item in lists" :key="item.id">
                  <div class="Noticder flex acenter">
                    <div class="flex w100" @click="goAD(item.id, 3)">
                      <span class="zhiding2 flex allcenter">运营</span>
                      <span style="width: 242px;overflow: hidden; text-overflow:ellipsis;   
                      color:#333333;  letter-spacing: 1px;
                white-space: nowrap;font-size: 14px;margin-right:48px">{{ item.name }}</span>
                      <p style=" color: #AAAAAA;margin-top:2px;margin-left: auto;
                " @click="goAD(item.id, 3)">
                        查看
                      </p>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="centerpart">
          <div style="height:505px;">
            <div class="box flex1" style="max-height: 658px;">
              <div class="Noticinnerbox" style="padding:20px 0px 20px 0px;position:relative">
                <div class="flex acenter nhead adcss">
                  <div v-for="(item, index) in adlist" :key="index" @click="clickad(item.id, index)" style="font-weight: bold;
font-size: 18px;display: flex;justify-content: space-around;width: 100%;cursor:pointer;
color: #FF7226;">
                    <div :class="{ adactive: index == thead }" class="flex allcenter">{{ item.name }}</div>
                  </div>
                </div>
                <div class="flex column">
                  <div class="today2 flex acenter"
                    style="width:100%;color:#7F7F7F;font-size:14px;border-bottom: 1px solid #F2F2F2;height:48px; margin-bottom: 20px;">
                    <div>{{ thead == 0 ? '今日游戏' : thead == 1 ? '明日预告' : '热门游戏' }}</div>
                    <div style="margin-left:auto">开区时间</div>
                  </div>
                  <!-- 广告内容 -->
                  <div style=" overflow-y: scroll;height:505px">
                    <div @click.stop="goCarousel(item.linkUrl)" v-for="item in todaylist" :key="item.id"
                      style="color:#333333;width:100%;cursor:pointer" class="Notic flex"
                      >
                      <!-- :style="{borderBottom: item.id % 5 == 0 ? '1px solid #F2F2F2' : '', height: item.adCount > 1 ? `${50}px` : '50px'}" -->
 <div class="today" :style="item.id > 5 && item.id < 11 ? ' border: 2px solid #D8BB86;;background:#D8BB86' : item.id > 10 ?
      'border: 2px solid #AAAAAA;background:#AAAAAA' : ''" v-show="thead == 0">今</div>
                      <div class="today" :style="item.id > 5 && item.id < 11 ? ' border: 2px solid #D8BB86;background:#D8BB86' : item.id > 10 ?
      'border: 2px solid #AAAAAA;background:#AAAAAA' : ''" v-show="thead == 1">预</div>
                      <div class="today" :style="item.id > 5 && item.id < 11 ? ' border: 2px solid #D8BB86;background:#D8BB86' : item.id > 10 ?
      'border: 2px solid #AAAAAA;background:#AAAAAA' : ''" v-show="thead == 2">热</div>
                      <div style="letter-spacing: 1px; white-space: nowrap;"> {{ item.name }} 
                <i style="color:#E34A4A; font-size: 19px; font-weight: 600;"> <i style="font-size: 13px;">X</i>{{ item.adCount }}</i>  
              </div>
                      <div class="flex" style="color:#37B389;margin-left:auto;margin-right:6px">
                        <img src="@/assets/newpic/time.png" alt="" v-if="thead == 0"
                          style="width:18px;height:18px;margin-right:7px;">
                        <!-- .substring(0, 10) -->
                        
                        <div v-if="thead == 0"> {{ item?.time.split(" ")[1].slice(0, 5) }} </div>
                        <!-- .substring(5, 16) -->
                        <div v-else> {{ item?.time.substring(5, 16) }}</div>
                        <div style="border-bottom:1px solid #F2F2F2"></div>
                      </div>
                      <!-- <div class="flex acenter" style="height: 50px;" v-for="items in item.adCount" :key="items">
                      
                      </div> -->
                      
                    </div>

                  </div>
                </div>
                <div class="flex allcenter" style="color:#FF7226;cursor:pointer; position: absolute; bottom: 20px; left: 170px; font-size: 15px; font-weight: 600;" @click="goMoreAd">点击查看更多 <img
                    src="@/assets/newpic/jj.png" style="width:20px;height:20px"></div>
              </div>
            </div>

          </div>
          <img :src="smallhf?.imageUrl | montageImgUrl(that)" alt="" @click.stop="goCarousel(smallhf.linkUrl)" style="left:33px;top:57px;margin-top:179px;cursor:pointer;
     height:110px;width:486px; border-radius: 6px;">
          <div class="box flex1" style="min-height: 307px;margin-top:20px">
            <div class="Noticinnerbox" style="">
              <div class="flex acenter nhead">
                <span style="color:#404040;font-size: 18px;font-weight: bold;">怀旧专区</span>
                <span style="color: #7F7F7F;margin-left: auto;font-size: 12px;cursor:pointer"
                  @click="gont(1)">更多></span>

              </div>
              <div class="Notic flex" v-for="item in ydlists" :key="item.id" style="height:50px">
                  <div class="Noticder flex acenter">
                    <div class="flex w100 acenter" @click="goHotSearch(item.id)">
                      <!-- <span style="margin-left: 3px;">
                        <img :src="require('@/assets/notice/' + item.num + '.png')" alt=""
                          style="margin-right:10px;width:26px;height:30px;"></span> -->
                           <span class="zhiding3 flex allcenter">怀旧</span>
                      <span style="width: 237px;overflow: hidden; text-overflow:ellipsis;   
                      color:#333333;  letter-spacing: 1px;margin-right:10px;
                white-space: nowrap;font-size: 14px;">{{ item.name }}</span>
                    </div>
                  </div>

                </div>
              <!-- <div class="Notic flex" v-for="item in rlists" :key="item.id" style="height:50px">
                <div class="Noticder flex acenter">
                  <div class="flex w100" @click="goAD(item.id, 4)">
                    <span class="zhiding3 flex allcenter">精品</span>
                    <span style="width: 248px;overflow: hidden; text-overflow:ellipsis;   
                      color:#333333;  letter-spacing: 1px;
                white-space: nowrap;font-size: 14px;margin-right:60px">{{ item.title }}</span>
                    <p style=" color: #AAAAAA;margin-top:2px;margin-left: auto;
                " @click="goAD(item.id, 4)">
                      查看
                    </p>
                  </div>
                </div>

              </div> -->
            </div>
          </div>
        </div>
        <div class="rightpart">
          <div style="height:370px;">
            <div class="box flex1" style="min-height: 370px;">
              <div class="Noticinnerbox" style="">
                <div class="flex acenter nhead">
                  <span style="color:#404040;font-size: 18px;font-weight: bold;">热搜</span>
                  <span style="color: #7F7F7F;margin-left: auto;font-size: 12px;cursor:pointer"
                    @click="gont(4)">更多></span>
                </div>
                <div class="Notic flex" v-for="item,index in rlists" :key="item.id" style="height:50px">
                <div class="Noticder flex acenter">
                  <div class="flex w100" @click="goAD(item.id, 4)">
                    <!-- <span class="zhiding3 flex allcenter">精品</span> -->
                    <span>
                    <img :src="require('@/assets/notice/' + (index+1) + '.png')" alt=""
                          style="margin-right:10px;width:26px;height:30px;"></span>
                    <span style="width: 248px;overflow: hidden; text-overflow:ellipsis;   
                      color:#333333;  letter-spacing: 1px;
                white-space: nowrap;font-size: 14px;margin-right:60px">{{ item.title }}</span>
                    <!-- <p style=" color: #AAAAAA;margin-top:2px;margin-left: auto;
                " @click="goAD(item.id, 4)">
                      查看
                    </p> -->
                  </div>
                </div>

              </div>
                <!-- <div class="Notic flex" v-for="item in ydlists" :key="item.id" style="height:50px">
                  <div class="Noticder flex acenter">
                    <div class="flex w100 acenter" @click="goHotSearch(item.id)">
                      <span style="margin-left: 3px;">
                        <img :src="require('@/assets/notice/' + item.num + '.png')" alt=""
                          style="margin-right:10px;width:26px;height:30px;"></span>
                      <span style="width: 237px;overflow: hidden; text-overflow:ellipsis;   
                      color:#333333;  letter-spacing: 1px;margin-right:10px;
                white-space: nowrap;font-size: 14px;">{{ item.name }}</span>
                    </div>
                  </div>

                </div> -->
              </div>
            </div>

          </div>
          <div style="margin-top:23px">
            <div class="box flex1" style="max-height: 732px; height: 732px;">
              <div class="Noticinnerbox">
                <div class="flex acenter nhead" style="margin: 4px 0 10px;">
                  <!-- margin-bottom:13px -->
                  <span style="color:#404040;font-size: 18px;font-weight: bold;">游戏测评</span>
                  <span style="color: #7F7F7F;margin-left: auto;font-size: 12px;cursor:pointer"
                    @click="gont(2)">更多></span>
                </div>
                <div class="Gotic flex acenter" v-for="item in assesslist" :key="item.id" style="align-items: baseline;">
                  <div class="Goticder flex  acenter" @click="goAD(item.id, 2)">
                    <img :src="item.url | montageImgUrl(that)" alt="" style="width:98px;height:68px;border-radius: 4px">
                    <!--  white-space: nowrap;overflow: hidden; text-overflow:ellipsis; -->
                    <span style="
                      color: #404040;margin-left: 14px;margin-right: 7px;letter-spacing: 1px;
                      width: 138px;font-size:14px; display:-webkit-box;overflow: hidden;text-overflow: ellipsis;
word-wrap: break-word;-webkit-line-clamp: 3;-webkit-box-orient: vertical
">{{ item.name }}
                    </span>
                    <div class="flex column acenter">
                      <div style="color: #37B389;height:28px">评分</div>
                      <div class="flex allcenter"
                        style="font-weight:bold;width:36px;height:20px;color: #37B389;border-radius: 4px;font-size:20px;font-style:italic">
                        {{ item.score.toString().length > 1 ? item.score : Number(item.score).toFixed(1) }}
                      </div>
                    </div>
                  </div>

                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
   </div>
    <GoTop></GoTop>
  </div>
</template>

<script>
// BigInt.prototype['toJSON'] = function () { return this.toString() }
import api from "@/api/api2.js";
import $ from 'jquery'
import SearchBox from "@/components/SearchBox/index.vue";
// 插入免费专区和开服预告 已开推荐组件
import Preview from "@/components/Preview/index.vue";
import Recommended from "@/components/Recommended/index.vue";
// import FreeArea from "@/components/FreeArea/index.vue";
import BrandArea from "@/components/BrandArea/index.vue";
// import api from "@/api/api.js";
import Good from "@/components/Good/index.vue"
import TodayOpen from "@/components/TodayOpen/index.vue"

export default {
  data() {
    return {
      that: this,
      photoAds: '', // 图文广告
      bighf: '',
      smallhf: '',
      // 广告名
      adlist: [
        {
          id: 1,
          name: '今日新区',

        },
        {
          id: 2, name: '明日预告',

        },
        {
          id: 3,
          name: '昨日已开',

        },

      ],
      todaylist: [],
      // 轮播图
      lblist1: [],
      lblist: [],
      current: 0,
      yc: false,
      thelogo: require("../../assets/images/书法有千年1.png"),
      items: [],
      imgAd: [],
      pic1: require("../../assets/images/书法有千年1.png"),
      lists2: [],
      lists: [],
      rlists: [],
      ydlists: [],
      list: [],
      size: Math.ceil(document.documentElement.clientHeight / 60), //每页有几条
      // total:'',//总条数
      current: 1, //当前在第几页
      page: "", //总页数
      // 改成null就不报错
      onOff: false,
      opentable: 1,
      current_rpctive: 0,
      current_table: 0,
      current_table2: 0,
      current_makeup: 0,
      // 综合的样式
      current_newactive: 0,
      thead: 0,
      current_type: 0,
      isShowGood: true,
      isShowMyFooter: false,
      adimg4: {
        id: 1,
        webUrl: 'www.youqnn.net',
        place: 8,
        imgUrl: require('@/assets/adpic/8.png')
      },
      //  二级页面的图片
      innerpic: {
        id: 1,
        webUrl: 'https://www.baidu.com/',
        place: 11,
        imgUrl: 'https://api.3000y.cc:2096/media/DEFAULT_IMAGE_AD/3cdb34434d5a4c0b88df6086b18c7c6c.jpg'
      },
      size: Math.ceil(document.documentElement.clientHeight / 43) || 20,
      imgList: [],
      bigpic: {
        id: 1,
        webUrl: 'www.youqnn.net',
        place: 8,
        imgUrl: require('@/assets/adpic/8.png')
      },
      centerpic1: {
        id: 1,
        webUrl: 'www.youqnn.net',
        place: 7,
        imgUrl: require('@/assets/adpic/7.png')
      },
      rightpic: {
        id: 1,
        webUrl: 'www.youqnn.net',
        place: 9,
        imgUrl: require('@/assets/adpic/9.jpg')
      },
      picList: [],
      itemsPerRow: 8,
      hodlist: [],
      table2list: [
        {
          id: 1,
          name: '今日开测'
        },
        {
          id: 2,
          name: '开测预告'
        },
        {
          id: 3,
          name: '已开推荐'
        },
        {
          id: 4,
          name: '其他游戏'
        },

      ],
      forumlist: [
        {
          id: 1,
          name: '资讯',
          img: require('@/assets/head/资讯.png'),
        },
        {
          id: 2,
          name: '专栏',
          img: require('@/assets/head/专栏.png'),
        },
        {
          id: 3,
          name: '测评',
          img: require('@/assets/head/测评.png'),
        },
        {
          id: 4,
          name: '攻略',
          img: require('@/assets/head/攻略.png'),
        }, {
          id: 5,
          name: '商城',
          img: require('@/assets/head/商城.png'),
        },
        {
          id: 6,
          name: '论坛',
          img: require('@/assets/head/论坛.png'),
        },
        {
          id: 7,
          name: '技术',
          img: require('@/assets/head/技术.png'),
        },
        // {
        //   id: 5,
        //   name: '免费专区'
        // },
      ],
      // 综合
      newlist: [
        {
          id: 1,
          name: '推荐',

        },
        {
          id: 2,
          name: '新闻',

        },
        {
          id: 3,
          name: '公告',

        },
        {
          id: 4,
          name: '活动',

        },

      ],
      // 综合右边图
      newrlist: [],
      newmsg: [],
      makeuplist: [
        {
          id: 1,
          name: '热门推荐',

        },
        // {
        //   id: 2,
        //   name: '三端',

        // },
        // {
        //   id: 3,
        //   name: '手游',

        // },
        // {
        //   id: 4,
        //   name: '端游',

        // },
        // {
        //   id: 6,
        //   name: '页游',

        // },

        // {
        //   id: 5,
        //   name: '免费专区'
        // },
      ],
      typelist: [],
    };
  },
  components: {
    // FreeArea,
    Recommended,
    BrandArea,
    Preview,
    SearchBox,
    Good,
    TodayOpen,
    GoTop: (resolve) => {
      require(["@/components/GoTop/index.vue"], resolve);
    },
  },
  created() {
    this.homeXhf();
    this.hf();
    this.xlb();
    this.dlb();
    // 图文广告
    this.twgg();
    // this.getHotad();
    this.wjyd();
    this.yxrs();
    // 技术服务
    this.yxcp();
    this.jsfw()

    this.getTodayList();
    // 综合新闻
    this.getzh();
    //  this.getImagesad();
    this.$bus.$on("logo", (data) => {
      this.thelogo = data;
      // console.log(this.thelogo, 'this.thelogo');
    });
    const logo = JSON.parse(sessionStorage.getItem('logo')) || {}
    if (logo) {
      this.thelogo = logo
      // console.log(this.thelogo, logo);
    }

  },
  mounted() {


  },
  destroyed() {
    //销毁监听

  },
  filters: {
    montageImgUrl(url, that) {
      if (url?.indexOf('http') == -1) {
        return that.$httpUrl + url;
      } else {
        return url
      }
    }
  },
  methods: {
    goCarousel(url) {
      console.log('轮播的点击', url)
      if (!/^https?:\/\//i.test(url)) {
        // 如果不包含，则在字符串前加上 "https://"
        url = "https://" + url;
      }
      window.open(url)
    },
    dlb() {
      let data = {
        current: 1,
        // 每页的条数
        size: 5,
        data: {}
      };
      api.dlb(data).then((res) => {
        console.log(res.records, '轮播')
        this.lblist1 = res.records.map((item, index) => {
          return {
            id: item.id,
            linkUrl: item.linkUrl,
            url: item.imageUrl,
          }
        })

        console.log(this.lblist1, ' this.lblist1')
      }).catch((error) => {
        console.error('API请求失败:', error);
      });
    },
    xlb() {
      let data = {
        current: 1,
        // 每页的条数
        size: 5,
        data: {}
      };
      api.xlb(data).then((res) => {
        console.log(res.records, '小轮播')
        this.lblist = res.records.map((item, index) => {
          return {
            id: item.id,
            linkUrl: item.linkUrl,
            url: item.imageUrl,
          }
        })

        console.log(this.lblist1, ' this.lblist1')
      }).catch((error) => {
        console.error('API请求失败:', error);
      });
    },
    hf() {
      let data = {
        current: 1,
        // 每页的条数
        size: 5,
        data: {}
      };
      api.hf(data).then((res) => {
        console.log(res.records, '小轮播')
        // this.bighf = res.records.map((item, index) => {
        //   return {
        //     id: item.id,
        //     linkUrl: item.linkUrl,
        //     url:item.imageUrl,
        //   }
        // })
        this.bighf = res.records[0]
        console.log(this.lblist1, ' this.lblist1')
      }).catch((error) => {
        console.error('API请求失败:', error);
      });
    },
    homeXhf() {
      let data = {
        current: 1,
        // 每页的条数
        size: 5,
        data: {}
      };
      api.homeXhf(data).then((res) => {
        console.log(res.records, '小轮播')
        // this.lblist = res.records.map((item, index) => {
        //   return {
        //     id: item.id,
        //     linkUrl: item.linkUrl,
        //     url:item.imageUrl,
        //   }
        // })
        this.smallhf = res.records[0]
        console.log(this.lblist1, ' this.lblist1')
      }).catch((error) => {
        console.error('API请求失败:', error);
      });
    }, 
    twgg() {
      let data = {
        current: 1,
        // 每页的条数
        size: 5,
        data: {}
      };
      // item.startTime.substring(5, 16)
      api.twgg(data).then((res) => {
        this.newrlist = res.records.map((item, index) => {
          return {
            id: item.id,
            name: item.title,
            time: item.startTime.substring(5, 16),
            url: item.iconUrl,
            img: item.imageUrl,
            linkUrl: item.linkUrl,
          }
        })
        this.photoAds = this.newrlist[0]
        console.log(this.newrlist, ' this.newrlist11111111111111111111111111为什么不循环')
      }).catch((error) => {
        console.error('API请求失败:', error);
      });
    },
    // 怀旧专区
    yxrs() {
      let data = {
        current: 1,
        // 每页的条数
        size: 6,
        data: {}
      };
      api.yxrs(data).then((res) => {
        this.ydlists = res.records.map((item, index) => {
          return {
            num: index + 1,
            id: item.id,
            name: item.title,
            linkUrl: item.linkUrl,
          }
        })

        console.log(res.records, '怀旧专区')
      }).catch((error) => {
        console.error('API请求失败:', error);
      });
    },
    // 热搜
    wjyd() {
      let data = {
        current: 1,
        // 每页的条数
        size: 6,
        data: {}
      };
      api.wjyd(data).then((res) => {
        this.rlists = res.records.map((item, index) => {
          return {
            id: item.id,
            title: item.title,
          }
        })
        console.log(res.records, '拿到热搜！！！！！')
      }).catch((error) => {
        console.error('API请求失败:', error);
      });
    },
    yxcp() {
      let data = {
        current: 1,
        // 每页的条数
        size: 7,
        data: {}
      };
      api.yxcp(data).then((res) => {
        this.assesslist = res.records.map((item, index) => {
          return {
            id:item.id,
            name: item.title,
            url: item.imageUrl,
            score: item.score
          }
        })

        console.log(res.records, '拿到游戏测评')
      }).catch((error) => {
        console.error('API请求失败:', error);
      });
    },
    jsfw() {
      let data = {
        current: 1,
        // 每页的条数
        size: 8,
        data: {}
      };
      api.jsfw(data).then((res) => {
        this.lists2 = res.records.slice(0, 4).map((item, index) => {
          return {
            id: item.id,
            name: item.title,
            url: item.imageUrl
          }
        })
        this.lists = res.records.slice(4, 7).map((item, index) => {
          return {
            id: item.id,
            name: item.title,
          }
        })
        console.log(this.lists, '技术文章后面')

        console.log(res.records, '拿到技术服务')
      }).catch((error) => {
        console.error('API请求失败:', error);
      });
    },
    getzh() {
      let data = {
        current: 1,
        // 每页的条数
        size: 5,
        data: {}
      };
      api.getArticleByzh(data).then((res) => {

        this.newmsg = res.records.map((item, index) => {
          return {
            // id: item.id,
            id: item.id,
            title: item.title,
            createTime: item.createTime,
            categoryId: item.categoryId
          }
        })
        console.log(this.newmsg, '--------------综合')
        console.log(res.records, '拿到综合')
      }).catch((error) => {
        console.error('API请求失败:', error);
      });
    },
    getxw() {
      let data = {
        current: 1,
        // 每页的条数
        size: 5,
        data: {}
      };
      api.getArticleByxw(data).then((res) => {
        this.newmsg = res.records.map((item, index) => {
          return {
            // id: item.id,
            id: item.id,
            title: item.title,
            createTime: item.createTime,
            categoryId: item.categoryId
          }
        })


        console.log(res.records, '拿到新闻')
      }).catch((error) => {
        console.error('API请求失败:', error);
      });
    },
    getgg() {
      let data = {
        current: 1,
        // 每页的条数
        size: 5,
        data: {}
      };
      api.getArticleBygg(data).then((res) => {
        this.newmsg = res.records.map((item, index) => {
          return {
            // id: item.id,
            id: item.id,
            title: item.title,
            createTime: item.createTime,
            categoryId: item.categoryId
          }
        })

        console.log(res.records, '拿到公告')
      }).catch((error) => {
        console.error('API请求失败:', error);
      });
    },
    gethd() {
      let data = {
        current: 1,
        // 每页的条数
        size: 5,
        data: {}
      };
      api.getArticleByhd(data).then((res) => {
        this.newmsg = res.records.map((item, index) => {
          return {
            // id: item.id,
            id: item.id,
            title: item.title,
            createTime: item.createTime,
            categoryId: item.categoryId
          }
        })

        console.log(res.records, '拿到活动')
      }).catch((error) => {
        console.error('API请求失败:', error);
      });
    },
    goMoreAd() {
      const routeOne = this.$router.resolve({
        path: "/moreAd",
      });
      window.open(routeOne.href, "_blank");
    },
    goAD(num, value, typeId) {
      if(typeId != 24) {
        console.log(value, '拿到类型')
        sessionStorage.setItem("type", JSON.stringify(value));
        const routeOne = this.$router.resolve({
          name: "AdDetails",
          params: {//要传的参数
            id:num,

          }
        });
        window.open(routeOne.href, "_blank");
      } else {
        window.open('https://fanyi.baidu.com/?aldtype=16047#en/zh/')
      }
      

    },

    getTodayList() {
      let data = {
        current: 1,
        // 每页的条数
        size: 100,
        data: {}
      };
      api.jrxq(data).then((res) => {
        this.todaylist = res.records.map((item, index) => {
          return {
            id: index + 1,
            num: item.id,
            name: item.title,
            time: item.startTime,
            adCount: item.adCount,
            linkUrl: item.linkUrl,
          }
        })
        console.log(res.records, '拿到今天广告')
      }).catch((error) => {
        console.error('API请求失败:', error);
      });
    },
    // 以往
    previouslist() {
      let data = {
        current: 1,
        // 每页的条数
        size: 100,
        data: {}
      };
      api.previous(data).then((res) => {
        this.todaylist = res.records.map((item, index) => {
          return {
            id: index + 1,
            num: item.id,
            name: item.title,
            time: item.startTime,
            adCount: item.adCount,
            linkUrl: item.linkUrl,
          }
        })
        console.log(res, '拿到以往广告')
      });
    },
    // 
    tomorrowlist() {
      let data = {
        current: 1,
        // 每页的条数
        size: 100,
        data: {}
      };
      api.tomorrow(data).then((res) => {
        this.todaylist = res.records.map((item, index) => {
          return {
            id: index + 1,
            num: item.id,
            name: item.title,
            time: item.startTime,
            adCount: item.adCount,
            linkUrl: item.linkUrl,
          }
        })
        console.log(res, '拿到tomorrow广告')
      });
    },

    goHotSearch(id) {

      const routeOne = this.$router.resolve({
        name: "hotSearch",
        params: {//要传的参数
          id: id
        }
      });
      window.open(routeOne.href, "_blank");
    },
    goAssess(id) {
      this.$router.push({
        name: "AdDetails", params: {
          id: id,
        },
      });
      // if (item.isSubPage == 1) {
      //   // 1二级页面 2链接
      //   // 路由跳转
      //   // console.log(item,'拿到id')
      //   this.$router.push({
      //     name: "AdDetails", params: {
      //       id: item.id,
      //     },
      //   });
      // } else {

      //   window.open(item.webUrl);
      // }  
    },
    gopad(item) {
      if (item.isSubPage == 1) {
        // 1二级页面 2链接
        // 路由跳转
        // console.log(item,'拿到id')
        this.$router.push({
          name: "AdDetails", params: {
            id: item.id,
          },
        });
      } else {

        window.open(item.webUrl);
      }
      // console.log(item.id, '热词做判断去二级页面还是链接', item.type);

    },
    // getHotad() {
    //   api.getHotad().then((res) => {
    //     this.hotwords = res;
    //     // console.log(this.hotwords, "按钮拿到的热词");
    //     // 按钮样式
    //     this.typelist.forEach((obj1, index1) => {
    //       const place1 = obj1.place;
    //       // console.log(place1, 'place1' .slice(0, 6));
    //       const obj2 = this.hotwords.find(obj => obj.place === place1);
    //       // console.log(obj2, 'obj2');
    //       if (obj2) {
    //         // console.log(obj2, 'obj2');
    //         // 找到了具有相同 place 属性的对象
    //         // 替换 obj1 对应的对象
    //         this.typelist[index1] = obj2;

    //       }
    //     });
    //     // console.log(this.typelist, '拿到的重组后的按钮样式this.typelist');
    //     // 文字样式
    //     this.hodlist.forEach((obj1, index1) => {
    //       const place1 = obj1.place;
    //       const obj3 = this.hotwords.find(obj => obj.place === place1);
    //       // console.log(obj3, 'obj3');
    //       if (obj3) {
    //         this.hodlist[index1] = obj3;

    //       }

    //     });
    //     // console.log(this.hodlist, ' 拿到的重组后热词this.hodlist');
    //   });
    // },
    // goforum(id) {
    //   window.open('www.youqnn.net');
    //   console.log(id, 'id');
    // },
    gotype2(item, index) {
      this.current_type = index;
      // if (item.isSubPage == 1) {
      //   // 1二级页面 2链接
      //   // 路由跳转
      //   this.$router.push({
      //     name: "AdDetails", params: {
      //       id: item.id,
      //     },
      //   });
      // } else {
      //   window.open(item.webUrl);
      // }


    },
    gotype3(item, index) {
      // 点击新闻类的
      if (index == 1) {
        // 
        console.log('点击新闻')
        this.getxw()
      } else if (index == 2) {
        console.log('点击公告')
        this.getgg()
      } else if (index == 3) {
        console.log('点击活动')
        this.gethd()
      } else {
        // 推荐
        this.getzh()
      }
      this.current_newactive = index;
      // if (item.isSubPage == 1) {
      //   // 1二级页面 2链接
      //   // 路由跳转
      //   this.$router.push({
      //     name: "AdDetails", params: {
      //       id: item.id,
      //     },
      //   });
      // } else {
      //   window.open(item.webUrl);
      // }


    },
    clickad(item, index) {
      console.log('点击', this.thead, index)

      this.thead = index;
      if (index == 0) {
        this.getTodayList()
      } else if (index == 1) {
        return this.$router.push({
          name: "moreAd",
          query: {
            type: 1
          }
        })
        // 点击明天
        this.tomorrowlist()
      } else {
        return this.$router.push({
          name: "moreAd",
          query: {
            type: 2
          }
        })
        // 点击以往
        this.previouslist()
      }

    },
    gotype4(item, index, itemUrl) {
      this.current_rpctive = index;
      this.photoAds = itemUrl
      console.log('触发了这个');
    },
    gohot(item, index) {
      if (item.isSubPage == 1) {
        // 1二级页面 2链接
        // 路由跳转
        this.$router.push({
          name: "AdDetails", params: {
            id: item.id,
            hotName: item.hotName,
          },
        });
      } else {

        window.open(item.webUrl);
      }


    },
    gotype(id, index) {
      this.current_makeup = index;

    },
    gont(value) {
      console.log(value, '拿到类型')
      sessionStorage.setItem("type", JSON.stringify(value));
      //综合列表页
      const routeOne = this.$router.resolve({
        name: "allList",
      });
      window.open(routeOne.href, "_blank");
    },
    gonew(value) {
      console.log(value, '拿到类型')
      sessionStorage.setItem("type", JSON.stringify(value));
      const routeOne = this.$router.resolve({
        path: "/newList",
      });
      window.open(routeOne.href, "_blank");

    },
    //     handleScroll() {
    //       var sidewidth = $('#J_BdSide').width();
    //       let scrollY = window.pageYOffset || document.documentElement.scrollTop;
    //       // 屏幕可见区域高度
    //       var windowHeight = document.body.clientHeight;  //网页可见区域高
    //       var apphight = document.body.scrollHeight; //网页正文全文高
    //       var sideHeight = document.getElementById("J_BdSide").scrollHeight;  //右侧盒子整体高度
    //     /*
    //    侧边滚动动态固定

    //  */   const sideTop = 586; //侧边栏头部距离
    //       const footer = 250;
    //       let topH = sideHeight + sideTop - windowHeight + footer; //计算出屏幕外部的高度
    //       //屏幕底部有未显示内容
    //       if (topH > 0) {
    //         //判断滚动高度
    //         if (scrollY >= topH) {
    //           let ltop = sideTop - topH;
    //           //判断是否已经固定
    //           if (this.$refs.J_BdSide.style.position != 'fixed' && ltop <= 0) {
    //             // console.log('滚动固定底部====>', 'topH===>', topH,
    //             //  'apphight===>', apphight, 'sh===>', scrollY, 'ltop==>', ltop);
    //             $('#J_BdSide').css({
    //               'position': 'fixed',
    //               'top': ltop + 'px',
    //               'height': sideHeight + 'px',
    //               'width': sidewidth + 'px',
    //             });
    //           } else if (this.$refs.J_BdSide.style.position != 'fixed') {
    //             //console.log('固定头部====>', 'topH===>', topH, 'sh===>', scrollY, 'position', this.$refs.J_BdSide.style.position);
    //             $('#J_BdSide').css({
    //               'position': 'static'
    //             });
    //           };

    //           //滚动条向上滚动
    //         } else if (scrollY <= topH && this.$refs.J_BdSide.style.position != 'static') {
    //           //console.log('解除固定====>', 'topH===>', topH, 'sh===>', scrollY, 'position', this.$refs.J_BdSide.style.position);
    //           $('#J_BdSide').css({
    //             'position': 'static'
    //           });

    //         }

    //       } else {
    //         //console.log('全部解除====>', 'topH===>', topH, 'sh===>', scrollY, 'position', this.$refs.J_BdSide.style.position);

    //         $('#J_BdSide').css({
    //           'position': 'static'
    //         });
    //       }
    //       /**
    //        *  侧边滚动动态固定END
    //        */

    //     },

    // getNoticeByPage() {
    //   let data = {
    //     current: this.current,
    //       // 每页的条数
    //       size: 8,
    //   };
    //   api.getNoticeByPage(data).then((res) => {
    //  console.log(res,'拿到消息')

    //   });
    // },



    look(id) {
      // console.log('跳转详情页', id);
      // window.removeEventListener("scroll", this.handleScroll);
      // console.log(id,'id')
      this.$router.push({
        name: "Notcontent",
        params: {
          id: id,

        },
      }); //点击进入详情页面
    },
    choosetable(index) {
      this.current_table = index;
      // if (index == 0) {
      //   this.opentable = 1;
      // }
      // console.log(this.current_table, 'ddd')
    },
    arrowClick(val) {

      if (val === 'right') {
        this.$refs.cardShow.next()
      } else {
        this.$refs.cardShow.prev()
      }
    },
    choosetable2(index) {
      this.current_table2 = index;
      if (index == 0) {
        this.opentable = 1;
      } else if (index == 1) {
        this.opentable = 2;
      } else if (index == 2) {
        this.opentable = 3;
      } else if (index == 3) {
        this.opentable = 4;
        // console.log('其他游戏')
      } else {
        this.opentable = 5;
      }
      // console.log(this.current_table, 'ddd')
    },
    // getImagesad() {
    //   api.getImagesad().then((res) => {
    //     this.imgAd = res;
    //     // console.log(res, '拿到图片2')
    //     // 写入一个5个空位置的arr数组
    //     const arr = Array.from(Array(5), () => new Array());
    //     // console.log(arr,'arr');
    //     // arr.map((item, index) => {
    //     //   const list = JSON.stringify(
    //     //     imgAd.filter((items) => items.place - 1 == index)
    //     //   ).replace(new RegExp("/media/", "g"),  "/media/")
    //     //   console.log(list,'list');
    //     //   if (index <= 7) {
    //     //     // place为3的前三张
    //     //     this.imgList.push(JSON.parse(list));
    //     //   } else {
    //     //     this.adimg4.push(JSON.parse(list));
    //     //   }
    //     // });
    //     // 顶部6循环图
    //     this.picList.forEach((obj1, index1) => {
    //       const place1 = obj1.place;
    //       const obj2 = this.imgAd.find(obj => obj.place === place1);
    //       if (obj2) {
    //         obj2.imgUrl = obj2.imgUrl;
    //         this.picList[index1] = obj2;
    //       }

    //     });
    //     // console.log(this.picList, '拿到图片广告');
    //     // 顶部右测大图7
    //     this.imgAd.map((item, index) => {
    //       if (item.place == 7) {
    //         // place为3的前三张
    //         item.imgUrl = item.imgUrl;
    //         this.centerpic1 = item;
    //         // console.log('拿到位置7的item', item, 'this.centerpic1', this.centerpic1)
    //       } else if (item.place == 8) {
    //         // 精品上面的长图
    //         item.imgUrl = item.imgUrl;
    //         this.bigpic = item;

    //       } else if (item.place == 9) {
    //         // 精品上面的长图的右边图
    //         item.imgUrl = item.imgUrl;
    //         this.rightpic = item;
    //         sessionStorage.setItem("rightpic", JSON.stringify(item));
    //         // console.log('内页好像也要')

    //       } else if (item.place == 10) {
    //         // 精品下面的长图
    //         item.imgUrl = item.imgUrl;
    //         this.adimg4 = item;

    //       } else if (item.place == 11) {
    //         item.imgUrl = item.imgUrl;
    //         sessionStorage.setItem("innerpic", JSON.stringify(item));
    //         // console.log('内页图片存起来')
    //         //二级页面的长图
    //         //  item.imgUrl=this.$httpUrl+ item.imgUrl;
    //         //  this.rightpic = item;

    //       }
    //     });
    //     // 中部左大图和右小图
    //     // console.log(this.picList, ' this.picList');
    //     this.isShow = true;
    //   });
    // },
  },
};
</script>

<style lang="scss" scoped>
.leftArrow {
  width: 48px;
  height: 69px;
  background: #FF7226;
  position: absolute;
  left: 0;
  z-index: 99;
  top: 16%
}
.big {
  background-color: #fff;
}
.rightArrow {
  width: 48px;
  height: 69px;
  background: #FF7226;
  position: absolute;
  right: 0;
  z-index: 99;
  top: 16%
}

.leftbar {

  width: 246px;
  height: 330px;
}

.adcss {
  border: 1px solid #FF7226;
  border-radius: 6px;
  background: #FBEFEA;
}


.centerPart {
  margin-top: 3px;
  justify-content: space-between;



  .leftpart {
    width: 398px;
    // border: 1px solid pink;

  }

  .centerpart {
    width: 486px;
    // border: 1px solid green;

  }

  .rightpart {
    width: 328px;


  }
}

.qidai {
  letter-spacing: 2px;
  font-size: 12px;
  font-weight: 500;
  height: 28px;
  color: #404040;
  width: 70px;
  top: 60px;
  left: 30px;
  position: absolute;
  background: white;
  border: 1px solid #ececec;
}

.Gotic {
  // height: 100%;
  cursor: pointer;
  // border-top: 1px solid #ececec;
  // /* border-bottom:1px solid #ECECEC; */
  // height: 50px;
  background: #ffffff;

  &:first-child {
    border-bottom: 1px solid #ececec;
  }
}

.Goticder {
  // border: 1px solid red;
  color: #606266;
  font-size: 12px;
  width: 100%;
  margin-bottom: 27px
}

.two {
  .bigpic {
    cursor: pointer;
    margin-top: 16px;
    width: 890px;
    height: 110px;
    border-radius: 5px;
  }
}

.headtop {
  justify-content: space-between;
  letter-spacing: 1px;
  // padding: 0 20px 0 20px;
  // margin-top: 49px;
  width: 100%;
  height: 330px;
  color: #404040;

  .leftTop {
    margin-left: 52px;
    padding: 10px 20px 0 20px;
    width: 511px;
    height: 330px;
    background: rgba(0, 0, 0, 0);
    // border-radius: 6px 6px 6px 6px;
    border: 1px solid #F2F2F2;

    .newcss {
      width: 40px;
      margin-right: 10px;
      border-radius: 2px;
      height: 20px;
      font-size: 12px;
      color: #37B389;
      border: 1.8px solid #37B389;
      line-height: 14px;
    }

    .green1 {
      color: #37B389;
      border: 1.8px solid #37B389;
    }

    .orange1 {
      color: #D8BB86;
      border: 1.8px solid #D8BB86;
    }

    .red1 {
      color: #E34A4A;
      border: 1.8px solid #E34A4A;
    }
  }

  .rightTop {
    width: 698px;
    height: 330px;

  }
}

// 


// 装饰页样式
.makeupactive {
  color: #FF7226 !important;
  background: #FBEFEA !important;
}

.newactive {
  font-weight: bold;
  border-bottom: 4px solid #ff8f19 !important;
  color: #ff8f19 !important;
}

.adactive {
  width: 96px !important;
  border-radius: 20px;
  height: 30px;
  background: #FF7226 !important;
  color: #fff !important;

}

// 新闻右侧
.newactive2 {


  border-left: 4px solid #ff8f19 !important;

}

.theleftbar {
  width: 245px;
}

.theleftbar:hover {


  border-left: 4px solid #ff8f19 !important;

}
.w100 {
  width: 100%;
  align-items: center;
}
.makeup {
  // padding: 0 20px 0 20px;
  margin-top: 15px;
  width: 100%;
  height: 401px;
  color: rgb(96, 98, 102);

  border-radius: 5px;

  .makeup_top {
    padding-top: 10px;
    padding-bottom: 10px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    height: 60px;
    border: 1px solid #D7D7D7;

    &_head {
      padding-right: 20px;

      .makeupcss {
        color: #404040;
        width: 700px;
        padding-left: 20px;
        font-size: 16px;
      }

      &_left :hover {

        color: #FF7226 !important;
        background: #FBEFEA !important;
      }

      &_left {

        // padding:10px;
        margin-right: 20px;
        width: 88px;
        font-size: 16px;
        // font-weight: bold;
        // border: 1px solid red;
      }
    }
  }

  .makeup_bottom {
    height: 340px;

    border-left: 1px solid #D7D7D7;
    border-right: 1px solid #D7D7D7;
    border-bottom: 1px solid #D7D7D7;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    // padding: 15px;
    padding-top: 19px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 14px;

    .makeup_rpic {
      cursor: pointer;
      width: 200px;
      height: 300px;
      border-radius: 5px;
    }

    &_pic {
      width: 940px;

      display: flex;
      justify-content: space-between;
    }

    &_choose {

      padding-top: 10px;
      height: 100%;

      .leftcho {

        height: 100%;
        // margin-right: 9px;
        color: #404040;

        .choosecss {
          height: 100%;

          .typeactive {
            border: 1px solid #FF7226;
            color: #FF7226 !important;
            background: #FBEFEA !important;
          }

          &_iner :hover {
            border: 1px solid #FF7226;
            color: #FF7226 !important;
            background: #FBEFEA !important;
          }

          &_iner {
            margin-bottom: 15px;
            border: 1px solid #D7D7D7;
            width: 80px;
            font-size: 14px;
            // font-weight: bold;
            // border: 1px solid  #FF7226;
            border-radius: 5px;
          }
        }
      }

      .rightcho {
        width: 100%;
        // border:1px solid red;
        // height:calc(100% - 22px);

        // padding-bottom: 20px;
        .hottext {
          // padding:10px;
          font-size: 14px;
          width: 100%;
          height: 100%;
          color: #404040;
          // padding-top: 4px;
          flex-wrap: wrap;
          //首尾对齐 
          text-align: justify;

          // justify-content: space-around;
          .item-row {
            // border:1px solid red;
            // border:1px solid red;
            // height:41px;
            height: 30px;
            padding-bottom: 26px;
            padding-top: 3px;
            // padding-left: 14px;
            // justify-content: space-around;
            // width: calc( 100% - 10px);
            width: 109px;
            // border: 1px solid red;
          }

          .item {
            cursor: pointer;
            /* 这里添加样式来设置项目的外观 */
            width: 112px;
            height: 24px;
            // line-height: 30px;
            // border:1px solid pink;
            // padding-top: 5px;
            // padding-left:20px;

            // margin-right: 5x; 
          }
        }
      }
    }

    .overlay {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      color: white;
      height: 25px;
      font-size: 14px;
    }
  }
}

.nhead {
  height: 50px;
}

.authinlet {
  cursor: pointer;
  margin-top: 16px;
  margin-left: 20px;
  width: 290px;
  height: 110px;
  border-radius: 5px;
}

.techicon {
  line-height: 18px;
  padding: 0 6px;
  border-radius: 2px;
  border: 1px solid #eee;
  font-size: 12px;
  color: #999;
}

.rightplace {
  // border:1px solid black;
  height: 100%;
}

#J_BdSide::-webkit-scrollbar {
  display: none;
}

.Noticinnerbox {
  margin-left: 16px;
  margin-right: 16px;
  width: calc(100% - 32px);
  height: 100%;

  // min-height: 500px;

  /* background:red; */
  h2 {
    padding-left: 11px;
    padding-top: 20px;
    font-size: 18px;
    color: #222226;
    padding-bottom: 20px;
  }

  .Notic {
    height: 100%;
    cursor: pointer;
    // border-top: 1px solid #ececec;
    /* border-bottom:1px solid #ECECEC; */
    height: 50px;
    background: #ffffff;


    // &:first-child {
    //   border-bottom: 1px solid #ececec;
    // }

    .Noticder {

      // position:absolute;
      // top:20px;
      color: #404040;
      font-size: 12px;
      // margin-left: 12px;
      /* background:green; */
      width: 100%;
      // justify-content: center;
      // margin-right: 130px;

      /* 边距让按钮最靠右 */
      .zhiding {
        margin-right: 10px;
        border-radius: 3px;
        text-align: center;
        // display: inline-block;
        font-size: 12px;
        width: 36px;
        height: 20px;
        background: #D9001B;
        color: #ffffff;
      }

      .gonggao {
        width: 36px;
        height: 20px;
        text-align: center;
        margin-right: 10px;
        border-radius: 4px 4px 4px 4px;
        opacity: 1;
        border: 1px solid #404040;
      }
    }

    .lookup {
      font-size: 14px;
      color: #909399;
      border: 1px solid #909399;

      &:hover {
        color: #909399;
        border: 1px solid #909399;
      }
    }
  }
}

.Toticinnerbox {
  // margin-left: 20px;
  // margin-right: 20px;
  // width: calc(100% - 40px);
  width: 100%;
  height: 100%;

  // min-height: 500px;

  /* background:red; */
  h2 {
    padding-left: 20px;
    padding-top: 20px;
    font-size: 18px;
    color: #222226;
    // padding-bottom: 20px;
  }

  .Totic {
    padding-left: 10px;
    height: 100%;
    cursor: pointer;
    // border-top: 1px solid #ececec;
    /* border-bottom:1px solid #ECECEC; */
    height: 40px;
    background: #ffffff;

    &:first-child,
    &:last-child {
      // border-bottom: 1px solid #ececec;
    }

    .Toticder {
      color: #606266;
      font-size: 14px;
      margin-left: 12px;
      height: 100%;
      /* background:green; */
      width: 100%;
      justify-content: center;
      // margin-right: 130px;

      /* 边距让按钮最靠右 */

    }

    .lookup {
      font-size: 14px;
      color: #909399;
      border: 1px solid #909399;

      &:hover {
        color: #909399;
        border: 1px solid #909399;
      }
    }
  }
}

.box {
  border-radius: 5px;

  background-color: #fff;
  min-height: 451px;
  border: 1px solid #f0f0f2;

}

.box1 {
  border-radius: 5px;
  margin-top: 10px;
  margin-left: 20px;
  background-color: #fff;
  // min-height: 451px;
  border: 1px solid #f0f0f2;

}





.pic {
  div {
    overflow: hidden;
  }

  img {
    -moz-transition: all 1s ease 0s;
    -o-transition: all 1s ease 0s;
    -webkit-transition: all 1s ease 0s;
    transition: all 1s ease 0s;
  }

  a:hover img {
    transform: scale(1.15);
    -webkit-transform: scale(1.15);
    -moz-transform: scale(1.15);
    -o-transform: scale(1.15);
  }

}

.el-carousel {
  width: 100%;
}

.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 150px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n+1) {
  background-color: #d3dce6;
}

.tablebtn:hover {
  // margin-bottom:5px;
  // border-bottom: 2px solid #222226;
  color: #FFFEFD !important;
  background: #FF7226 !important;
}

.active {
  // margin-bottom:5px;
  // border-bottom: 2px solid #222226;
  color: #FFFEFD !important;
  background: #FF7226 !important;
  // background: #222226 !important;
}

.haveborder2 {
  border-right: 1px solid #D7D7D7;

}

.haveborder {
  border-right: 1px solid gray;
}

.tablebtn {

  // padding-bottom: 5px;
  // margin-top: 10px;
  // margin-right: 10px;
  // letter-spacing: 6px;
  // margin-bottom: 1px solid #e8e8ed;
  color: #404040;
  // background: #f1f2f3;
  height: 42px;
  // padding-top:20px;
  // padding-bottom: 20px;
  // width: 400px;
  cursor: pointer;

  div {
    // 
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 10%;
    font-size: 16px;
    // color:white;
    // background:green;
    // height: 30px;
    // border:1px solid red;
  }


}

.hotbtn {

  // padding-bottom: 5px;
  // margin-top: 10px;
  // margin-right: 10px;
  // letter-spacing: 6px;
  // margin-bottom: 1px solid #e8e8ed;

  color: rgb(96, 98, 102);

  // background: #f1f2f3;
  height: 30px;
  // width: 400px;
  cursor: pointer;

  div {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 10%;
    font-size: 14px;
    // color:white;
    // background:green;
    // height: 30px;
    // border:1px solid red;
  }

  .haveborder {
    border-right: 1px solid gray;
  }

}

.form {
  background: pink;
}

::v-deep .searchinput {
  width: 271px;
  font-size: 13px;
}

::v-deep .searchinput .el-input__inner {
  height: 29px !important;
  line-height: 29px !important;
  background: #fcfcfc;
}

img {
  width: 100%;
  height: 100%;
}



.outer {


  .banner1,
  .banner2 {
    margin-top: 22px;
    // margin-bottom: 20px;
    position: relative;
  }

  /* 三图片广告位 */

  .banner2 {
    img {
      width: 100%;
      height: 160px;
      border-radius: 4px;
    }

    .angle_mark2 {
      position: absolute;
      /* top: -50px;
    right: -50px; */
      top: 133px;
      width: 60px;
      height: 27px;
      background: #ff7325;
      border-top-right-radius: 9px;
      border-bottom-left-radius: 3px;

      & span {
        position: absolute;
        display: inline-block;
        color: #fff;
        width: 100%;
        bottom: 2px;
        left: 0;
        text-align: center;
        letter-spacing: 4px;
        font-size: 14px;
      }
    }
  }
}

/* 没数据时表格盒子 */
.tablebox {
  width: 100%;
  height: 100%;
  background: white;
}

/* 表格头部 */
.tablename {
  height: 40px;
  width: 100%;
  background: blue;
  justify-content: center;
  align-items: center;
}

.bx {
  // position: absolute;
  // top:20px;
  background-color: #fff !important;
  margin: 0 auto;
  justify-content: center;
  width: 1260px;
}
</style>
