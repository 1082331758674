<template>
  <div class="app-content" :class="tableType">
    <div class="top">
      <span class="row-item row-item1" style="margin-right: 64px;width:122px">服务器名</span>
      <span class="row-item row-item2" style="width:177px">开测时间</span>
      <span class="row-item row-item3 " style="margin-right: 30px;margin-left: 55px">版本简介</span>
      <span class="row-item row-item4" style="margin-left:34px;" v-if="isOpenHot==true">助力冲榜</span>
      <span class="row-item row-item4" style="margin-left: 106px;" v-else>游戏详情</span>
    </div>
    <div class="nothingdata" v-if="alltable.length == 0">
      <img src="@/assets/images/no result.png" alt="" />
      <p>暂无数据~</p>
    </div>
    <div class="conten" :style="{
      backgroundColor: conBoxStyle.bgC,
      color: conBoxStyle.color,
    }">
      <div v-for="(item, index) in bigtable" :key="index" class="eachLine"
        @click.stop="lineClick(item.btnType, item.webUrl, item.id,item.isSubPage)">
        <div class="row" v-if="!item.contentType" :class="item.classObj?.row">
          <!-- :style="{
          backgroundColor: item.skin?.bgC,
          color: item.skin?.color,
          ...(index == bigtable.length - 1
            ? {
              border: 'none',
            }
            : {}),
        }" -->
          <div class="row-item row-item1">
            <div class="server-name">
              {{ item.serverName }}
              <!-- <a :href="item.webUrl" target="_blank"> </a> -->
            </div>
            <!-- <div class="today"  >今</div> -->
            <div class="today" v-if="item.hotArea != null">今</div>

            <div class="today2 flex allcenter" v-if="item.hotArea == null">今</div>

            <div class="number" v-if="item.multipleTotal > 1 && typeName == '今日开服'">
              x
              <span>
                {{ item.multipleTotal }}
              </span>
            </div>
            <div class="number" v-if="item.topNumber > 1 && typeName == '精品置顶'">
              x
              <span>
                {{ item.topNumber }}
              </span>
            </div>
            <div class="jp flex allcenter">精</div>
          </div>
          <div class="row-item row-item2">
            {{ item.openTime }}
          </div>
          <div class="row-item row-item3">
            <!-- :class="item.isOpenHot" -->
            <div class="game-type" :style="{
              backgroundColor: item.gameTypes.bgC,
              color: item.gameTypes.color,
            }">
              {{ item.gameTypes.name }}
            </div>
            <span>
              {{ item.versionDescribe }}
            </span>
          </div>
          <div class="row-item row-item4">
            <div class="hot-spot">
              <img v-if="isOpenHot == true" src="@/assets/images/fire.png" alt="" style="margin-right: 10px;" />
              <span v-if="isOpenHot == true">{{ item.totalSpot || 0 }}</span>
            </div>
            <el-button class="pay" @click.stop="
              goHelp(item.btnType, item.webUrl, item.id, item.multipleTotal,item.isSubPage)
              ">
              {{item.btnType }}
            </el-button>
          </div>
          <!-- <div class="row-item row-item5"> -->
          <!--  v-show="openhot" -->
          <!-- <div class="hot-spot" >
              <img   v-if="isOpenHot==true" src="@/assets/images/fire.png" alt=""   />
              <span  v-if="isOpenHot==true">{{ item.totalSpot || 0 }}</span>
            </div> -->

          <!-- <el-button class="pay" @click.stop="
              goHelp(item.btnType, item.webUrl, item.id, item.multipleTotal)
            ">
              {{ item.btnType }}
            </el-button> -->
          <!-- </div> -->
        </div>
        <!-- <div v-if="item.contentType == 'pic' && imgShow" class="item-pic flex allcenter"> -->
        <!-- :style="this.typeName=='精品置顶'?margin-bottom: 15px" -->
        <!-- <a :href="item.webUrl" target="_blank" style="display: flex"> -->
        <!-- <span class="advert-tag">广告</span> -->
        <!-- <img :src="item.imgUrl" style="width: 1100px;height: 110px;cursor: pointer;margin-bottom: 15px;" /> -->
        <!-- </a> -->
        <!-- </div> -->
        <div v-if="item.contentType == 'tag'" class="tag-box">
          <div :class="item.className"></div>
        </div>
      </div>
    </div>

    <login v-show="showLogin" @hidlogin="hidlogin" @hidlogin2="hidlogin2" @loginOk="loginOk"></login>
    <!-- 弹框 -->
    <my-dialog ref="myDialog" @hiddenDialog="hiddenDialog" :id="id" :type="dialogType" :loginOk="loginOk2"
      @closeDialog="dialogType = 1"></my-dialog>
  </div>
</template>

<script>
import api from "@/api/api.js";
import login from "@/components/login.vue";
import MyDialog from "./components/MyDialog.vue";
// const btnTypes = ["去助力", "上火爆区", "点击进入"];
const btnTypes = ["去助力", "点击进入"];
const gameTypes = [
  {
    name: "三端",
    bgC: "#2094ff",
  },
  {
    name: "源码",
    bgC: "#00D2B9",
  },
  {
    name: "手游",
    bgC: "#2AB91E",
  },
  {
    name: "端游",
    bgC: "#FFA49E",
  },
];

export default {
  // props: ["alltable", "typeName", "pic", "tabStyle"],
  props: {
    imgShow: {
      default: false
    },
    freetable: {},
    alltable: {},
    typeName: {},
    pic: {},
    tabStyle: {},
    show: {
      default: true
    },
    show2: {
      default: false
    },
  },
  components: {
    MyDialog, login
  },

  data() {
    let tableType = "";
    let conBoxStyle = {
      bgC: "#FFFFFF",
      color: "#000000",
    };
    switch (this.typeName) {

      case "精品置顶":
        tableType = "table-1";
        break;
      case "今日开服":
        tableType = "table-2";
        break;
      case "已开推荐":
        tableType = "table-3";
        break;
      case "开服预告":
        tableType = "table-3";
        break;
      default:
        break;
    }
    if (this.tabStyle) {
      conBoxStyle = {
        ...(this.tabStyle || {}),
      };
    }
    return {

      showLogin: false,
      dialogType: 0,
      conBoxStyle,
      tableType,
      btnTypes,
      bigtable: this.alltable,
      todayfreetable: this.freetable,
      multiple: 1,
      //  把这条广告的id存在这
      id: 0,
      isOpenHot: null,
      hidDialog: null,
      loginOk2: 0,
      freetodaylist: [],
    };
  },
  watch: {
    freetable(val) {
      // console.log(val,'val')
      if (val) {
        this.todayfreetable = val;
      }

    },
    alltable(val) {
      this.bigtable = this.handleList(val);
    },

    'hidDialog': {
      handler(newval, oldval) {
        if (newval == false) {
          this.$refs.myDialog.outerVisible(false);
        }
      }
    }
  },

  created() {
    this.bigtable = this.handleList(this.alltable);

  },
  mounted() {

    this.$bus.$on('isOpenHot', (data) => {
      this.isOpenHot = true;

    })
  const openhot = JSON.parse(sessionStorage.getItem("openhot")) || [];
    
    if(openhot){
      this.isOpenHot =openhot
    }
  },

  methods: {

    loginOk() {
      this.loginOk2 = 1
      this.showLogin = false
      this.$refs.myDialog.setShow(true);
    },
    hidlogin() {
      this.showLogin = false
      this.$refs.myDialog.setShow(false);
    },
    hidlogin2() {
      this.showLogin = false
    },
    // 没数据注释今日开服的      table-2写到下面试试
    handleList(val) {

      let list_ = val.filter((item) => item); // 去除手游类型;
      if (this.tableType == "table-2") {
        // 今日
        // 更据发布条数分为上下部分
        // 
        // const arr_A = list_.filter((item) => item.hotArea === 1);
        // const arr_B = list_.filter((item) => item.hotArea === 0);
        const arr_A = list_;
        // 把免费广告数据放在今日开服免费区
        const arr_B = this.todayfreetable;
        if (arr_A && arr_A.length > 0) {
          arr_A.unshift({ contentType: "tag", className: "tag1" });
        }
        let pic = []
        this.pic.forEach(e => {
          if (e[0].place == 5) {
            pic.push(e[0])
          }
        });
        arr_A.push({ contentType: "pic", ...(pic[0]) });
        if (arr_B && arr_B.length > 0) {
          arr_B.unshift({ contentType: "tag", className: "tag2" });
        }
        const A = this.handleExtendList(arr_A);
        // console.log(arr_A,'arr_a')
        const B = this.handleExtendList(arr_B);
        list_ = A.concat(B);
        // console.log(list_, 'dddlist_')
        // list_ = this.handleExtendList(list_);
      }
      // if (this.tableType == "table-1" || this.tableType == "table-2") {
      //   list_.push({ contentType: "pic", ...this.pic[0]?.[0] });
      // }
      if (this.tableType == "table-1") {
        // 精品
        list_.push({ contentType: "pic", ...this.pic[0]?.[0] });
        list_ = this.handleExtendList(list_);
      }
      //  if (this.tableType == "table-1") {
      //     list_.push({ contentType: "pic", ...this.pic[0]?.[0] });
      //   }
      // list_ = this.handleExtendList(list_);
      list_.map((item, index) => {
        // 游戏标签
        item.gameTypes = {
          ...gameTypes[item.gameTypeId - 1],
          // color: item.labelColor,
          // bgC: item.labelBackgroundColor || gameTypes[item.gameTypeId - 1].bgC,
        };

        // 按钮
        if (this.isOpenHot == true) {
          item.btnType = btnTypes[0];
        } else {
          item.btnType = btnTypes[1];
        }

        // if (this.tableType == "table-1") {
        //   item.btnType = btnTypes[0];
        // }
        // if (this.tableType == "table-2" && item.hotArea === 0) {
        //   item.btnType = btnTypes[0];
        // }
        if (this.tableType == "table-1") {
          const time = item.openTime && item.openTime.split(" ");
          item.openTime = time?.[0];
        }
        return item;
      });
      return list_;
    },

    handleExtendList(list) {
      // console.log(list,'list1')

      let arr = [];
      if (list.className && list[0].className == "tag2") {
        // console.log('lalaal')
        list.map((item, index) => {

          // console.log(item.className.indexOf('tag2') != -1,'className')
          if (item.contentType == undefined || item.contentType == "") {


            Array.from({ length: 1 }, (_, idx) => {
              arr.push({
                ...this.handleTabItemStyle(item, index, idx),
                ...item,
                multipleTotal: 1,

              });
            });
          } else {
            arr.push({
              ...item,
            });
          }
        });
      } else {


        // 扩展条数
        // 火爆 精品
        // console.log(list[0].className,'dd')

        list.map((item, index) => {

          // console.log(item.className.indexOf('tag2') != -1,'className')
          if (item.contentType == undefined || item.contentType == "") {

            let len =
              this.tableType == "table-1" ? item.topNumber : item.multipleTotal;
            // console.log(len,'ddd')
            // console.log(item.topNumber,'ddd')
            Array.from({ length: len || 1 }, (_, idx) => {
              arr.push({
                ...this.handleTabItemStyle(item, index, idx),
                ...item,
              });
            });
          } else {
            arr.push({
              ...item,
            });
          }
        });
      }
      return arr;
    },
    handleTabItemStyle(item, index, idx) {
      let classObj = {
        row: "",
      };
      let skin = {};
      if (idx > item.topNumber - 1) {
        classObj.row = classObj.row + " showJP";
      }
      // 让上火暴区样式 删除 统一今日开服样式
      // (this.tableType == "table-2" && item.hotArea === 0)
      // if (this.tableType == "table-2" && && item.hotArea) {
      //   // 灰色样式
      //   classObj.row = classObj.row + " chuanggui";
      // }
      // 让上火暴区样式 删除 统一今日开服样式
      // (this.tableType == "table-2" && item.hotArea === 0)
      if (this.tableType == "table-2" && item.hotArea == null) {
        // 灰色样式
        classObj.row = classObj.row + " chuanggui";
      }
      // 
      if (idx <= item.skinNumber - 1) {
        skin = {
          bgC: item.backgroundColor || "#fafabb", //  item.backgroundColor ,
          color: item.fontColor || "#ff6d1a", //item.fontColor,
        };
      }
      return {
        skin,
        classObj,
      };
    },
    goHelp(values, url, id, multipleTotal,isSubPage) {
      this.id = id;
      const userInfo = JSON.parse(sessionStorage.getItem("userInfo")) || {};
      // if (userInfo.userLoginDTO) {
      if (values === "去助力") {
        this.multiple = multipleTotal;
        // this.$refs.myDialog.setShow(true);
        this.dialogType = 1;
        if (userInfo.userLoginDTO) {
          this.id = id;
          this.dialogType = 1;
          this.showLogin = false
          this.multiple = multipleTotal;
          this.$refs.myDialog.setShow(true);
        } else {
          this.dialogType = 1;

          this.showLogin = true
        }
      }
      if (values === "上火爆区") {
        this.id = id;
        this.dialogType = 3;
        // this.$refs.myDialog.setShow(true);
        if (userInfo.userLoginDTO) {
          this.dialogType = 3;
          this.showLogin = false
          this.$refs.myDialog.setShow(true);
        } else {
          this.dialogType = 3;
          this.showLogin = true
        }
      }
      // } else {
      if (values === "点击进入") {
        window.removeEventListener("scroll", this.handleScroll);
        // window.open(url);
        if(isSubPage==1){
          // console.log(isSubPage,'isSubPage')
        this.$router.push({
          name: "AdDetails", params: {
            id: id,
          },
        });
      }else{
        window.open(url)
      }
        this.adHitCount(id)
      }
      // }

    },
    // 整行点击跳转链接
    lineClick(values, url, id,isSubPage) {
      // console.log('精品和今日的整行点击');
      window.removeEventListener("scroll", this.handleScroll);
   
      if (id) this.adHitCount(id)
      if(isSubPage==1){
        this.$router.push({
          name: "AdDetails", params: {
            id: id,
          },
        });
      }else{
        window.open(url)
      }
      // window.open(url);
     
    },
    // 广告点击量统计
    async adHitCount(id) {
      const res = await api.adHitCount({ id })
    },
    hiddenDialog() {
      this.$emit("getList", true);
    },
  },
};
</script>

<style lang="scss" scoped>
// 引入弹窗样式 只有今日开服和开服预告两个表格有
@import "@/common/Popup.scss";
// @import "@/common/table.scss";

/* 弹窗助力按钮统一样式放公告样式里了！ */
// 只有点击进入的表格间距和其他不一样  已开和推荐 所以要单独写

.app-content {
  // min-height: 424px;
  // width: 1100px;

  width: 890px;
  margin: 0 auto;
  padding-bottom: 0;
  background-color: #fff;

  .top {
    width: 100%;
    justify-content: flex-start;
    padding: 0 22px;
    display: flex;
    background: #F2F2F2;
    border: 1px solid #D7D7D7;

    .row-item {
      line-height: 41px;
      color: #404040;
      // font-weight: 550;
      font-size: 16px;
      letter-spacing: 3px;
      height: 41px;
    }

    &:nth-child(0) {
      .row-item5 {
        padding-left: 104px;
      }
    }

  }

  .eachLine {
    .row {
      &:hover {
        background: #fcfcfc !important;
        cursor: pointer;
      }
    }

  }

  .conten {
    border: 1px solid rgba(232, 232, 237, 0.801);
    font-size: 14px;
    background-color: #fff;
    text-align: left;
    font-weight: 500;
    letter-spacing: 1px;
    color: #404040;
    // padding-bottom: 64px;

    .row {
      display: flex;
      padding: 2px 12px 0px 22px;
      width: 100%;
      border-bottom: 1px solid rgba(232, 232, 237, 0.801);
      height: 33px;
      justify-content: space-between;
      position: relative;
    }

    .item-pic {
      width: 100%;
      // border:1px solid pink;
      // background: pink;
      // padding: 15px 23px 0;
      // background-color: #fff;
      // margin-top: -2px;
      margin-top: 22px;
      // margin-bottom: 15px;
      position: relative;

      a img {
        width: 1100px;
        height: 110px;
      }
    }

    .tag-box {
      width: 100%;
      height: 0px;
      position: relative;

      .tag1 {
        width: 41px;
        height: 106px;
        left: -50px;
        position: absolute;
        background-image: url(@/assets/images/火爆.png);
        background-size: contain;
        background-repeat: no-repeat;
        pointer-events: none;
      }

      .tag2 {
        width: 41px;
        height: 106px;
        left: -50px;
        position: absolute;
        background-image: url(@/assets/images/free.png);
        background-size: contain;
        background-repeat: no-repeat;
        pointer-events: none;
      }
    }
  }

  .row-item {
    display: flex;
    align-items: center;
    white-space: nowrap;
    flex-shrink: 0;
    position: relative;
    padding-left: 3px;

    span {
      flex-shrink: 0;
      max-width: 196px !important;
      overflow: hidden;
    }
  }
}

.table-1 {
  // border:1px solid pink;
  margin-bottom: 15px;

  .row-item6 {
    padding-left: 100px;
  }

  .conten {
    .row {}
  }

  .row-item {
    font-weight: normal;

    span {
      max-width: 204px;
    }

    .today {
      display: none;
    }

    .today2 {
      display: none;
    }

    .jp {
      position: absolute;
      left: -30px;
    }

    .game-type {
      position: absolute;
      left: -44px;
    }

    .number {
      margin-left: 5px;
    }

    .hot-spot {

      width: 69px;

      span {

        width: 72px;
      }
    }
  }

  .row-item1 {
    width: 120px;
    margin-left: 30px;
  }

  .row-item2 {
    justify-content: flex-start;
    width: 140px;
    padding-left: 20px;
    // text-indent: 22px;

    span {
      width: 5;
    }
  }

  .row-item3 {

    margin-left: 20px;
    width: 190px;

  }

  .row-item4 {

    // border:1px solid red;
    width: 149px;

  }

  .row-item5 {
    width: 180px;
    // padding-left: 104px;
  }
}

.table-2 {
  // border:1px solid pink;
  margin-bottom: 15px;

  .row-item6 {
    padding-left: 100px;
  }

  .conten {
    .row {}
  }

  .row-item {
    font-weight: normal;

    span {
      max-width: 204px;
    }

    .today {
      position: absolute;
      left: -30px;

      // display: none;
    }

    .today2 {
      display: none;
    }

    .jp {
      margin-left: 8px;
      // border:2px solid black;
      // position: absolute;
      // left: -30px;
    }

    .game-type {
      position: absolute;
      left: -44px;
    }

    .number {
      margin-left: 5px;
    }

    .hot-spot {
      width: 69px;

      span {
        width: 72px;
      }
    }
  }

  .row-item1 {
    width: 120px;
    margin-left: 30px;
  }

  .row-item2 {
    justify-content: flex-start;
    width: 140px;
    padding-left: 20px;
    // text-indent: 22px;

    span {
      width: 5;
    }
  }

  .row-item3 {
    // border:1px solid red;
    margin-left: 20px;
    width: 190px;
  }

  .row-item4 {
    width: 149px;
  }

  .row-item5 {
    width: 180px;
    // padding-left: 104px;
  }
}

.table-3 {
  padding-bottom: 70px;

  .conten {
    .row {
      // font-size: 14px;
    }
  }

  .row-item {
    .today {
      display: none;
    }

    .today2 {
      display: none;
    }

    .jp {
      position: absolute;
      left: -30px;
    }

    .game-type {
      position: absolute;
      left: -40px;
    }

    .hot-spot {
      display: none;
    }
  }

  .row-item1 {
    width: 120px;
    margin-left: 30px;
  }

  .row-item2 {
    width: 170px;
  }

  .row-item3 {
    width: 190px;
  }

  .row-item4 {
    width: 190px;
  }

  .row-item5 {
    width: 90px;
  }

  .top .row-item5 {
    margin-right: -9px;
    margin-left: 7px;
  }
}

.table-4 {
  padding-bottom: 70px;

  .conten {
    .row {
      font-size: 14px;
    }
  }

  .row-item {
    span {
      max-width: 180px;
    }

    .today {
      display: none;
    }

    .today2 {
      display: none;
    }

    .jp {
      position: absolute;
      left: -30px;
    }

    .game-type {
      position: absolute;
      left: -40px;
    }
  }

  .row-item1 {
    width: 120px;
    margin-left: 30px;
  }

  .row-item2 {
    width: 170px;
  }

  .row-item3 {
    width: 190px;
  }

  .row-item4 {
    width: 190px;
  }

  .row-item5 {
    width: 175px;
  }
}

// 常规推荐样式
.app-content .conten .chuanggui {
  color: #a3a1a1;
  font-size: 14px;

  .row-item {
    span {
      max-width: 180px;
    }
  }

  .today {

    // color: #777777;
    // border-color: #a3a1a1;
    color: #fc5612;
    border-radius: 4px;
    border: 2px solid #fc5612;
  }

  .today2 {
    width: 23px;
    height: 23px;
    font-size: 12px;
    // color: #777777;
    // border-color: #a3a1a1;
    color: #a3a1a1;
    border-radius: 4px;
    border: 2px solid #a3a1a1;
  }

  .number {
    display: none;
  }

  .jp {
    display: none;
  }

  .game-type {
    display: none;
  }

  .hot-spot {
    display: none;
  }
}

// 皮肤
// .app-content .conten .skin1 {
//   color: #ff6d1a;
//   background-color: #fafabb;
//   // font-size: 14px;
//   .hot-spot {
//     color: #ff6d1a;
//   }
// }

// 精品图标
.app-content .conten .showJP {
  .jp {
    display: none;
  }
}
</style>


