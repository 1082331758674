var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"box",staticStyle:{"overflow":"hidden","background-color":"#fff"}},[_c('div',{staticStyle:{"width":"890px","margin":"0 auto","margin-top":"13px"}},[_c('div',{staticClass:"app-content",class:_vm.tableType},[_vm._m(0),(_vm.list.length == 0)?_c('div',{staticClass:"nothingdata"},[_c('img',{attrs:{"src":require("@/assets/images/no result.png"),"alt":""}}),_c('p',[_vm._v("暂无数据~")])]):_vm._e(),_c('div',{staticClass:"conten",style:({
        backgroundColor: _vm.conBoxStyle.bgC,
        color: _vm.conBoxStyle.color,
      })},_vm._l((_vm.bigtable),function(item,index){return _c('div',{key:index,staticClass:"eachLine",on:{"click":function($event){return _vm.lineClick(item.btnType, item.webUrl, item.id,item.isSubPage)}}},[(!item.contentType)?_c('div',{staticClass:"row",class:item.classObj?.row},[_c('div',{staticClass:"row-item row-item1",staticStyle:{"width":"195px"}},[(item.openTime.substring(0, 10) == _vm.getafterTime())?_c('div',{staticClass:"yg",staticStyle:{"width":"24px","height":"24px","line-height":"20px","color":"#E34A4A","border":"2px solid #E34A4A","text-align":"center","border-radius":"3px","margin-right":"10px"}},[_vm._v(" 明")]):_c('div',{staticClass:"yg",staticStyle:{"width":"24px","height":"24px","line-height":"20px","color":"rgb(255, 114, 37)","border":"2px solid rgb(255, 114, 37)","text-align":"center","border-radius":"3px","margin-right":"10px"}},[_vm._v(" 预")]),_c('div',{staticClass:"server-name",staticStyle:{"margin-right":"4px"}},[_vm._v(" "+_vm._s(item.serverName)+" ")]),(item.multipleTotal > 1)?_c('div',{staticClass:"number"},[_vm._v(" x "),_c('span',[_vm._v(" "+_vm._s(item.multipleTotal)+" ")])]):_vm._e(),_c('div',{staticClass:"jp",staticStyle:{"margin-left":"8px"}},[_vm._v("精")])]),_c('div',{staticClass:"row-item row-item2",staticStyle:{"text-indent":"7px"}},[_vm._v(" "+_vm._s(item.openTime)+" ")]),_c('div',{staticClass:"row-item row-item3",staticStyle:{"padding-left":"50px","width":"325px","position":"relative"}},[_c('div',{staticClass:"game-type",class:item.isOpenHot,style:({
                backgroundColor: item.gameTypes.bgC,
                color: item.gameTypes.color,
              })},[_vm._v(" "+_vm._s(item.gameTypes.name)+" ")]),_c('span',[_vm._v(" "+_vm._s(item.versionDescribe)+" ")])]),_c('div',{staticClass:"row-item row-item4",staticStyle:{"margin-left":"50px","width":"60px"}},[_vm._v(" "+_vm._s(item.counttime)+" ")])]):_vm._e()])}),0)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"top"},[_c('span',{staticClass:"row-item row-item1",staticStyle:{"margin-right":"73px","width":"142px","margin-left":"30px"}},[_vm._v("服务器名")]),_c('span',{staticClass:"row-item row-item2",staticStyle:{"width":"143px"}},[_vm._v("开测时间")]),_c('span',{staticClass:"row-item row-item3",staticStyle:{"margin-right":"52px","margin-left":"83px"}},[_vm._v("版本简介")]),_c('span',{staticClass:"row-item row-item4",staticStyle:{"margin-left":"191px"}},[_vm._v("距开测")])])
}]

export { render, staticRenderFns }