<template>
  <div id="app">
    <!-- <SidebarAd @closeSidebar="closeSidebar" v-if="SidebarAdShow"
      :sidebarImg="sidebarImg.length > 0 ? sidebarImg[6][0] : []"></SidebarAd>
    <SidebarBtm @closeSidebarBtm="closeSidebarBtm" v-if="SidebarAdBtmShow"
      :sidebarImg="sidebarImg.length > 0 ? sidebarImg[5][0] : []"></SidebarBtm> -->

    <MyHeader :givelogo.sync="mylogo"></MyHeader>
    <!-- 路由组件出口的地方 -->
    <login v-show="isshow" @hidlogin="hidlogin" @hidlogin2="hidlogin2"></login>
    <!-- <keep-alive> -->
    <router-view :key="$route.params.textTitle"></router-view>
    <!-- </keep-alive> -->
    <!-- rouer-view footer在登录注册组件隐藏 v-show="$route.path!='/'"所以在隐藏的组件上v-show-->
    <MyFooter :givelogo.sync="mylogo" :code.sync="code" :banquan.sync="banquan" :dataBody.sync="dataBody"></MyFooter>
  </div>
</template>

<script>
import api from "@/api/api2.js";
import MyHeader from "@/components/MyHeader/MyHeader.vue"
export default {
  data() {
    return {
      code: null,
      banquan:null,
      flag: null,
      isshow: false,
      theicon: require('../src/assets/images/LOGO.png'),
      mylogo: {
        topCode: '',
        topLogo: ''
      },
      sidebarImg: [],
      dataBody: {
        footerCode: '',
        linkUrl: '',
        footerFilings: '',
        copyrightInfo: '',
        footerInfo: '',
        footerLogo: '',
        linkName: '',
        footerFilingsUrl: '',
        linkList: []
      },
    };
  },
  name: "App",
  components: {
    login: (resolve) => {
      require(["@/components/login.vue"], resolve);
    },
    MyFooter: (resolve) => {
      require(["@/components/MyFooter/MyFooter.vue"], resolve);
    },
    MyHeader,

  },
  mounted() {
    //   // 绑定自定义事件
    this.$bus.$on('openregister', (data) => {
      this.isshow = true;
    })
  },
  created() {
    // 手机端的判断
    //   this.flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
    //  console.log(this.flag,'手机端')
    //   if (this.flag) {
    //     window.location.href = 'http://move.3000y.run'
    //   }
    this.aboutName();
    // var link =
    //   document.querySelector("link[rel*='icon']") ||
    //   document.createElement("link");
    // link.type = "image/x-icon";
    // link.rel = "icon";
    // link.href = this.theicon;
    // document.getElementsByTagName("head")[0].appendChild(link);
    // this.headIcon = link;
    // api.getContact().then((res) => {
    //   if (res == '侧边栏已关闭') this.sidebar = false
    // })

  },
  methods: {
    hidlogin() {
      this.isshow = false
    },
    hidlogin2() {
      this.isshow = false
    },
    // getBasicStatus() {
    //   api.getBasicStatus().then((res) => {
    //     console.log('打印状态',res)
    //     // console.log(res,'dddd')
    //     if (res.isOpenHot == 1) {
    //       res.isOpenHot = true;
    //       this.$bus.$emit("isOpenHot", true);
    //       sessionStorage.setItem("openhot", JSON.stringify(res.isOpenHot));
    //     } else {
    //       res.isOpenHot = false;
    //       sessionStorage.setItem("openhot", JSON.stringify(res.isOpenHot));
    //     }
    //     // 网站标题
    //     if (res.webName) {
    //       document.title = res.webName;
    //     }

    //     // 网站logores.logoUrl.length
    //     if (res.logoUrl) {
    //       this.mylogo = this.$httpUrl + res.logoUrl;
    //       // console.log( this.mylogo,' this.mylogo')
    //       this.$bus.$emit("logo", this.mylogo);
    //       sessionStorage.setItem("logo", JSON.stringify(this.mylogo));
    //     }
    //     // 图标赋值
    //     if (res.icoUrl) {
    //       // console.log(res,'dd')
    //       this.theicon = this.$httpUrl + res.icoUrl;

    //       this.headIcon.href = this.theicon;
    //     }
    //   });
    // },
    aboutName() {
      let data = {
        current: 1,
        // 每页的条数
        size: 10,
        data: {}
      };
      api.aboutName(data).then((res) => {
        console.log('打印状态11111111111111', res.records,res.records[7])
        // 二维码地址
        // if( res.records[0].imageUrl){
        //   this.code = res.records[0].imageUrl
        // }
        console.log(document.getElementsByTagName("head")[0],'document.getElementsByTagName("head")[0]')
        document.title = '7天下'
        res.records.forEach(item => {
          if (item.categoryId == 9) {
            this.mylogo.topCode = item.imageUrl
          } else if (item.categoryId == 4) {
            this.dataBody.footerLogo = item.icon
            this.mylogo.topLogo = item.icon
          } else if (item.categoryId == 8) {
            this.dataBody.footerCode = item.imageUrl
          } else if (item.categoryId == 5) {
            this.dataBody.linkList.push(item)
            console.log(this.dataBody.linkList, item, '哈哈哈哈哈哈哈哈哈哈哈哈哈')
            // this.dataBody.linkUrl = item.linkUrl
            // this.dataBody.linkName = item.title
          } else if (item.categoryId == 3) {
            this.dataBody.footerInfo = item.content
          } else if (item.categoryId == 6) {
            this.dataBody.copyrightInfo = item.content
          } else if (item.categoryId == 7) {
            this.dataBody.footerFilings = item.title
            this.dataBody.footerFilingsUrl = item.linkUrl
          } else if (item.categoryId == 2) {
            document.title = item.title
            this.$nextTick(() => {
              let link = document.querySelector("link[rel*='icon']") || document.createElement("link");
              link.type = "image/x-icon";
              link.rel = "shortcut icon";
              link.href = this.$httpUrl + item.imageUrl;
              document.getElementsByTagName("head")[0].appendChild(link)
            })
          }
        });
          
          // this.mylogo = res.records
        
        // 
        // this.mylogo = res.records[7].icon;

        
        // // 网站logores.logoUrl.length
        // if (res.logoUrl) {
        //   this.mylogo = this.$httpUrl + res.logoUrl;
        //   // console.log( this.mylogo,' this.mylogo')
        //   this.$bus.$emit("logo", this.mylogo);
        //   sessionStorage.setItem("logo", JSON.stringify(this.mylogo));
        // }
        // // 图标赋值
        // if (res.icoUrl) {
        //   // console.log(res,'dd')
        //   this.theicon = this.$httpUrl + res.icoUrl;

        //   this.headIcon.href = this.theicon;
        // }
      });
    },
  },
};
</script>

<style>
#app {
  font-family: 'Microsoft YaHei';
  /* 沉底 */
  width: 100% !important;
  /* min-height: 100%; */
  display: flex;
  flex-direction: column;
  /* min-height: 97%; */
  min-height: 97.5%;
  position: relative;
  max-width: 1914px;
  /* min-width: 1914px; */
  margin: 0 auto;
  /* background: url(./assets/images/发布站背景图.523d7c21.png) no-repeat; */
  /* background-size: 100% 100%; */
  /* background: url(./assets/发布站.png); */
  /* background: url(@/assets/images/bg.png);  */
  /* 图片固定，不随滚动条滚动 */
  /* background-attachment: fixed; */
  /* background-position: 50% 0;
  background-color: #e4e9e1; */

}

html,
body {
  height: 100%;
  background-color: #F5F6FA;
}
</style>
