<template>
  <div>
    <!-- 搜索栏 -->
    <div class="header flex">
      <!-- 搜索框 -->
      <div class="flex ">
        <div class="search-box">
          <div class="search-icon">
            <img src="@/assets/images/s2.png" style="width: 20px;
            height: 20px;" />
          </div>
          <input type="text" class="search-input" placeholder="搜索你感兴趣的游戏" v-model="tips" @keyup.13="GoSearch(tips)" />
          <button class="search-button" type="button" @click="GoSearch(tips)">搜索</button>

        </div>
      </div>

    </div>
  </div>
</template>

<script>
import api from "@/api/api.js";
export default {
  data() {
    return {
      // 搜索
      tips: "",
      changingtable: [],
      // 热词
      hotwords: [],
    };
  },

  created() {
  },
  methods: {

    GoSearch(tips) {
      // console.log('跳转搜索页',tips);
      window.removeEventListener("scroll", this.handleScroll);
     if (tips) {
      this.$router.push({ name: "MySearch", params: { tips: tips } });
      } else {
      this.$router.push({ path: '/' })
      }
    },}
 
};
</script>

<style lang="scss" scpoed>
.searchhead{ 

  //  border:1px solid red;
}
.searchbigbox{
  margin-top: 100px !important;

}
.search-box {
  height:37px;
  display: flex;
  width: 316px;
  border: 1px solid #A1A1A1;
  border-radius: 25px;
  overflow: hidden;
  .search-icon {
    padding: 7px;
    // background-color: #FDB813;
    color: white;
  }

  .search-input {
    flex-grow: 1;
    padding: 8px;
    border: none;
    font-size: 13px;
  }

  input {
    &:focus {
      outline: none;
    }
  }



  .search-button {
    width: 80px;
    padding: 8px;
    background-color: #FF7226;
    color: white;
    border: none;
    cursor: pointer;
  }
}



/* 头部 */
.header {

  width: 100%;
  // height: 110px;
  background-color: #ffffff;

}
</style>