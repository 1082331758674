<template>
  <div class="PublishTadbox flex column">
    <div class="paybox" v-if="paying == 1">
      <div class="Publishpay flex column">
        <div class="flex paymethods column">
          <div class="flex" style="margin-bottom:10px;margin-top: 10px;">支付方式</div>
          <div class="flex" style="position: relative;">
            <el-radio-group v-model="radio" @change="agreeChange(radio)" class="choose">
              <div>
                <el-radio v-model="radio" label="1" :disabled="dis">三千币</el-radio>
              </div>
              <div class="flex">
                <el-radio v-model="radio" label="2">微信</el-radio>
              </div>
              <div>
                <el-radio v-model="radio" label="3">支付宝</el-radio>
              </div>
            </el-radio-group>
            <span style="margin-top: -3px;margin-left: 20px;">可用：{{ money || 0 }}个</span>
            <el-button style="width: 70px;height: 30px;margin-top: -7px;margin-left: 30px;" size="mini" type="primary"
              @click="goRecharge" :disabled="dis">去充值</el-button>
            <!-- <span style="margin-top: -3px;margin-left: 40px;color: blue;cursor: pointer;">去充值</span> -->


            <!-- <div style="color: #263ea4; cursor: pointer" @click="goPayticket()">
              去充值> style="margin-right: 28px; width: 100px"
            </div> -->
          </div>
          <!-- 游客模式 -->
        </div>
      </div>
      <div class="text flex column">
        <div v-if="radio == 1">
          消耗三千币：<span style="color: #f56c6b">{{ useSqb }}个</span>
        </div>
        <div v-if="radio == 2">
          支付金额：<span style="color: #f56c6b">￥{{ useSqb }}.00</span>
        </div>
        <div v-if="(radio == 3)">
          支付金额：<span style="color: #f56c6b">￥{{ useSqb }}.00</span>
        </div>
        <div class="paybox">
          <!-- 三千币 -->
          <div class="box flex column acenter" v-if="radio == 1">
            <div style="color: #909399;margin-bottom: 10px;">请输入登录密码，确认支付</div>
            <form>
              <el-input v-model="vCode" type="password" show-password class="searchinput" placeholder="请输入登录密码">
              </el-input>
            </form>
          </div>
          <!-- 微信扫码支付 -->
          <div class="codebox flex column acenter" v-if="radio == 2">
            <!-- <img src="@/assets/images/code.png" alt="" @click="payit()"
              style="width: 112px;height: 110px;text-align: center;" /> -->
            <vue-qr v-if="qrCode" :text="qrCode" style="width: 120px ;height: 120px;"></vue-qr>
            <span v-else>加载中</span>

            <div class="payder flex">
              <span>微信扫一扫付款</span>
            </div>
            <el-button style="width: 80px;height: 30px;line-height: 0px;position: absolute;bottom: -45px;"
              @click="gopok(2)">关闭</el-button>
          </div>
          <!-- 支付宝扫码支付 -->
          <div class="codebox flex column acenter" id="aliform" v-if="(radio == 3)" style="padding-left: 20px;overflow: hidden;position: relative;">
            <iframe :srcdoc="aliform" frameborder="no" border="0" marginwidth="0" marginheight="0" scrolling="no"
              width="120" height="120" style="overflow:auto;position: absolute;">
            </iframe>
            <span style="position: absolute;bottom: 10px;right: 13px;">支付宝扫一扫支付</span>
          </div>
          <el-button  v-if="(radio == 3)" style="width: 80px;height: 30px;line-height: 0px;margin-left: 40.3%;margin-top: 14px;"
              @click="gopok(2)">关闭</el-button>
          <div class="botton flex" v-if="radio == 1">
            <el-button class="flex allcenter" type="cnumber" size="small" @click="gopok(2)">返回</el-button>
            <el-button type="primary" @click="gopok(1)" size="small" class="flex  diaon allcenter">确认支付
            </el-button>
          </div>
        </div>
      </div>
    </div>
    <div class="paybox" v-if="paying == 2" style="display: flex; flex-direction: column; align-items: center">
      <span style="margin-top: 15px">{{ radio == 1 ? `消耗三千币: ${useSqb}个` : `支付现金:￥${useSqb}.00` }}</span>
      <img src="@/assets/images/success.png" alt="" style="margin-top: 50px;" />
      <span style="margin-top: 15px">{{
          type == 1 ? "恭喜您！助力成功" : "恭喜您！成功入驻火爆专区"
      }}</span>
      <el-button plain @click="gopok(3)" style="margin-top: 70px" type="primary" class="diaon">关闭</el-button>
    </div>
    <div class="paybox" v-if="paying == 3" style="display: flex; flex-direction: column; align-items: center">
      <img src="@/assets/images/xx.png" alt="" />
      <span style="margin-top: 15px; color: red">{{
          "三千币不足，请移步论坛充值"
      }}</span>
      <span class="flex allcenter" style="margin-top: 45px">
        <el-button plain @click="goDiscuz" class="diaoff flex allcenter">立刻充值</el-button>
        <el-button type="primary" @click="gopok(3)" class="diaon">关闭</el-button>
      </span>
      <!-- <el-button plain @click="gopok(3)" style="margin-top: 15px" type="primary"
        >关闭</el-button
      > -->
    </div>
  </div>
</template>

<script>
import VueQr from 'vue-qr'
import api from "@/api/api.js";
import axios from 'axios';
export default {
  components: { VueQr },
  props: {
    id: {
      default: 1,
      type: Number,
    },
    type: {
      default: 1,
      type: Number,
    },
    paying: {
      default: 1,
      type: Number,
    },
    payok: {
      // default: 1,
      type: Number,
    },
    // sqb: {
    //   default: 0,
    //   type: Number,
    // },
    useSqb: {
      default: 0,
      type: Number,
    },
    whatad: {
      type: Number,
    },
  },
  data() {
    return {
      aliform: '',
      dis: false,
      money: null,
      qrCode: null, // 付款二维码
      //倒计时初始化
      count: "",
      // paying: false,
      // 支付成功
      radio: "1",
      havesqb: 1,
      vCode: "",
      orderNo: null,
      timer: null,
      timer2: null
    };
  },
  created() {
    const userInfo = JSON.parse(sessionStorage.getItem("userInfo")) || {};
    if (userInfo.userLoginDTO.id == 'xx') {
      this.dis = true
      this.radio = '2'
    }
    this.money = 0
    this.getMoney()
  },
  mounted() {
  },
  beforeDestroy() {
    // clearInterval(this.timer)
  },
  methods: {
    // 微信扫码支付
    getWxNatice() {
      this.qrCode = null
      let description = this.type
      if (description == 3) description = 2
      // console.log(this.id,this.useSqb, description);
      // 获取支付二维码
      api.getWxNatice({ adId: this.id, addHot: this.useSqb, description: description }).then((res) => {
        if (res) {
          clearInterval(this.timer)
          this.qrCode = res.codeUrl
          // 轮询是否支付成功
          this.timer = setInterval(() => {
            api.getOrderStatus({ orderNo: res.orderNo }).then((res2) => {
              if (res2 == 'ok') {
                this.$emit('payOk')
                clearInterval(this.timer)
                clearInterval(this.timer2)
              }
            })
          }, 1800);
        }
      })
    },
    // 支付宝扫码支付
    async getZFBNavtice() {
      // 获取页面中有几个表单
      let description = this.type
      if (description == 3) description = 2
      // console.log(this.id,this.useSqb, description);
      const res = await api.getPagePay({ adId: this.id, addHot: this.useSqb, description: description })
      if (res) {
        this.aliform = res.formStr
        clearInterval(this.timer2)
        // 轮询是否支付成功
        this.timer2 = setInterval(() => {
            api.getOrderStatus({ orderNo: res.orderNo }).then((res2) => {
              if (res2 == 'ok') {
                this.$emit('payOk')
                clearInterval(this.timer)
                clearInterval(this.timer2)
              }
            })
          }, 1800);
      }
    },
    getMoney() {
      let userInfo = JSON.parse(sessionStorage.getItem("userInfo")) || "";
      if (userInfo.userLoginDTO.id != 'xx') {
        api.getMoney().then((res) => {
          this.money = res
        })
      }
    },
    // 去充值
    goRecharge() {
      let auth = JSON.parse(sessionStorage.getItem("auth")) || "";
      if (auth) window.open("http://" + auth, 'newwindow');
      setTimeout(() => {
        window.open('http://www.3000y.com.cn/home.php?mod=spacecp&ac=credit&op=buy', 'newwindow')
      }, 300);
    },
    agreeChange(val) {
      if (val == 1) {
        this.havesqb = 1
      } else if (val == 2) {
        // 微信扫码支付
        this.havesqb = 0
      }
    },
    goDiscuz() {
      const auth = JSON.parse(sessionStorage.getItem("auth")) || "";
      window.open("http://" + auth);
      window.open("http://www.3000y.com.cn/forum.php");
    },
    gopok(val) {
      // this.$emit("handlePay", val);
      // 关闭页面
      if (val == 2) {
        this.vCode = ''
        clearInterval(this.timer)
        clearInterval(this.timer2)
        this.$emit("closePay");
      }
      if (val == 1) {
        if (this.vCode) {
          api
            .checkPassword({ password: this.vCode })
            .then((res) => {
              if (!res) {
                this.$message({
                  message: "密码错误，请重新输入",
                  type: "error",
                  center: true,
                });
                this.passForm.password = "";
              } else {
                clearInterval(this.timer)
                this.$emit("handlePay", val);
                // this.$message({
                //   message: "支付成功",
                //   type: "success",
                //   center: true,
                // });
              }
            })
            .catch(() => { });
        } else {
          this.$message({
            message: "密码不能为空",
            type: "error",
            center: true,
          });
        }
      }
      if (val == 3) {
        this.$emit("handlePay", val);
      }
    },
  },
  watch: {
    radio: {
      handler(newval) {
        if (newval == 2) {
          clearInterval(this.timer2)
          this.getWxNatice()
        } else if (newval == 3) {
          // 清除微信扫码轮询
          clearInterval(this.timer)
          this.getZFBNavtice()
        } else if (newval == 1) {
          clearInterval(this.timer)
          clearInterval(this.timer2)
          clearInterval(this.timer)
        }
      },
      immediate: true
    },
    'id': {
      handler(newval) {
      }
    },
    'type': {
      handler(newval, oldval) {
      },
      immediate: true
    }
  }
};
</script>
<style>
.el-dialog--center .el-dialog__body {
  padding: none;
}
</style>
<style lang="scss" scoped>
::v-deep .el-dialog--center .el-dialog__body {
  padding: 18px 25px 30px !important;
}

.codebox {

  width: 124px;
  height: 144px;
  border: 1px solid #979797;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 8px auto 0;
  position: relative;

  span {
    font-size: 12px;
    color: #606266;
  }
}

.payder {
  width: 100%;
  height: 30px;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #979797;
}

// 选中
// ::v-deep .el-radio {
//         margin-bottom: 15px;
//       }
::v-deep .el-radio__inner:hover {
  border-color: #ffdc82;
}

::v-deep .el-radio__label {
  color: #606266;
  // font-size: 20px;
}

::v-deep .el-radio__inner {
  border-color: #ffdc82;
  // background: none;
  // width: 20px;
  // height: 20px;
}

::v-deep.el-radio__input {
  vertical-align: sub;
}

::v-deep .el-radio__inner::after {
  background: #ffdc82;
}

::v-deep .el-radio__input.is-checked+.el-radio__label {
  color: #606266;
}

::v-deep .el-radio__input.is-checked .el-radio__inner {
  border-color: #ffdc82;
  background: #ffdc82;
}

// ::v-deep .el-radio, .el-radio__input{
//   line-height:0px;
// }
::v-deep .el-dialog--center .el-dialog__body {
  padding: 18px 25px 30px !important;
}

::v-deep .searchinput {
  width: 290px;

  font-size: 13px;
}

::v-deep .searchinput .el-input__inner {
  height: 32px !important;

  background: #fcfcfc;
}

.PublishTadbox {
  // background: #fcfcfc;
  width: 100%;
  height: 100%;
  background-color: #fff;

  .cnumber_header {
    background: #fff;
    z-index: 1;
    width: 100%;
    height: 60px;
    border-bottom: 1px solid #ececec;

    .cnumber_header_title {
      font-size: 16px;
      width: 100%;
      padding-right: 70px;
      // 居中而加的padding
    }
  }

  .paymethods {
    color: #606266;
    width: 100%;
    height: 102px;
    background: #fcfcfc;
    justify-content: center;
    padding-left: 9px;
    align-items: flex-start;
    display: flex;
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
    border: 1px solid #cccccc;

    .money {
      margin-top: 6px;
      // padding-bottom: 20px;
    }
  }

  .text {
    color: #606266;
    margin-left: 10px;
    margin-top: 10px;

    .paybox {
      // margin-top: 10px;
    }
  }

  .botton {
    // height: 30px;
    justify-content: flex-start;
    margin-top: 30px;
  }

  .el-radio {
    margin-bottom: 11px;
  }
}

.tips {
  margin-top: 15px;
  font-size: 9px;
  // padding-left: 32px;
  // padding-right: 32px;
  color: #f56c6b;
}

.box {
  margin-top: 20px;
}

.vcode_value {
  width: 280px;
  height: 38px;
  position: absolute;
  top: 0px;
  left: 50%;
  margin-left: -140px;

  background: none;
  opacity: 0;
}

.vcode_value_box {
  width: 100%;

  input {
    width: 32px;
    height: 32px;
    background: #ffffff;
    border-radius: 4px 4px 4px 4px;

    border: 1px solid #ececec;
    margin-left: 10px;
    text-align: center;
    font-size: 16px;
  }

  input:nth-child(1) {
    margin-left: 0px;
  }
}

// 正在支付中样式
.payingcontent {
  margin-top: 200px;
  width: 100%;
  height: 100%;
}

// 支付成功样式
.cnumber_content {
  margin-top: 201px;
  // background:green;
  height: 100%;

  .oktext {
    margin-left: 20px;

    p {
      // margin-bottom: 18px;
      font-size: 16px;
      color: #68c33a;
    }

    .okbtn {
      margin-top: 20px;
    }
  }
}

::v-deep .detailbtn .el-button {
  color: #ffffff;
  width: 80px;
  height: 30px;
  background: #263ea4;
  border: 1px solid #909399;
}

.detailbtn .el-button:last-child:focus,
.detailbtn .el-button:last-child:hover {
  color: #ffffff;
  background: #263ea4;
  border: 1px solid #263ea4;
}

.detailbtn .el-button:last-child:active {
  color: #ffffff;
  background: #263ea4;
  border: 1px solid #909399;
}

// 支付失败样式
.oktext {
  margin-left: 20px;

  p {
    // margin-bottom: 18px;
    font-size: 16px;
    color: #f56c6b;
  }

  .okbtn {
    margin-top: 20px;
  }
}
</style>