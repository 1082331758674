//配置路由的地方 需要引用vue  vue-router (插件都要用Vue.use方法) 
import Vue from 'vue';
import VueRouter from 'vue-router';
// 使用插件
// 搜索部分重复触发同一个路由 写这行代码
Vue.use(VueRouter);
const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

import MyHome from "@/pages/MyHome/MyHome.vue"
// 配置路由器
export default new VueRouter({
    // 配置路由们
    routes: [{
        path: "/",
        component: MyHome
    },
    {
        path: "/MySearch/:tips",
        component: () =>
            import('../pages/MySearch/MySearch.vue'),
        name: "MySearch"
    },
    {
        path: "/NoticeCenter",
        component: () =>
            import('../pages/NoticeCenter/NoticeCenter.vue'),
        name: "NoticeCenter",
        meta: {
            show: 'true',
        },
        children: [{
            // 想要传参在url显示 path要带参数 不然刷新会隐藏
            path: "/Notcontent/:id",
            name: "Notcontent",
            component: () =>
                import('../pages/NoticeCenter/Notcontent.vue')
        },]
    },
    {
        path: "/AdDetails/:id",
        component: () => 
            import('../pages/MyHome/AdDetails.vue'),
        name: "AdDetails",
    },
    {
        path: "/hotSearch/:id",
        component: () =>
            import('../pages/MyHome/hotSearch.vue'),
        name: "hotSearch",
    },
    {
        path: "/moreAd",
        component: () =>
            import('../pages/MyHome/moreAd.vue'),
        name: "moreAd",
    },
    {
        path: "/newList",
        component: () =>
            import('../pages/MyHome/newList.vue'),
        name: "newList",
    },
    {
        path: "/allList",
        component: () =>
            import('../pages/MyHome/allList.vue'),
        name: "allList",
    },
    {
        path: "/Aboutcontent/:textTitle",

        component: () =>
            import('../pages/NoticeCenter/Aboutcontent.vue'),
        name: "Aboutcontent"
    },

    ],
    scrollBehavior() {
        return {
            x: 0,
            y: 0
        }
    }
})