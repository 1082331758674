<template>
  <div ref="box" style=" overflow: hidden; background-color: #fff;margin-top: 13px;">
    <span style="color:#FF7226;float: right;margin-bottom: 13px;">本区展示截止至2023-11-16</span>
    <div style="width: 890px; margin: 0 auto">
      <!-- <Table :alltable.sync="list" typeName="开服预告" :tabStyle="{}"></Table> -->
      <div class="app-content" :class="tableType">
        <div class="top">
          <span class="row-item row-item1" style="width: 346px; margin-left: 30px">服务器名</span>
          <span class="row-item row-item2" style="width: 380px">服务器域名</span>
          <span class="row-item row-item4" style=" margin-left: 40px;width: 236px; ">游戏详情</span>
        </div>
        <div class="nothingdata" v-if="list.length == 0">
          <img src="@/assets/images/no result.png" alt="" />
          <p>暂无数据~</p>
        </div>
        <div class="conten">
          <div v-for="(item, index) in bigtable" :key="index" class="eachLine"
            @click.stop="lineClick(item.btnType, item.webUrl, item.id)">
            <div class="row" v-if="!item.contentType">
              <div class="row-item row-item1" style="width: 100px;margin-left: 10px; ">
                <div class="server-name" style="margin-right: 4px; ">
                  {{ item.serverName }}
                </div>
              </div>
              <!--  domainName webUrl-->
              <div class="row-item row-item2" style=" width:70px">
               
                {{ item.webUrl }}
              </div>
              <div class="row-item row-item5" style="width: 180px;padding-left: 96px;">

                <el-button class="pay2" @click.stop="
                  goHelp(
                    item.btnType,
                    item.webUrl,
                    item.id,
                    item.multipleTotal
                  )
                  ">
                  点击进入
                </el-button>
              </div>
            </div>
            <!-- 首页插入的图片广告 -->
            <div v-if="item.contentType == 'pic'" class="item-pic">
              <a :href="item.webUrl" style="display: flex">
                <span class="advert-tag">广告</span>
                <img :src="item.imgUrl" />
              </a>
            </div>
            <div v-if="item.contentType == 'tag'" class="tag-box">
              <div :class="item.className"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// let mock = require("./mock.json");
import api from "@/api/api.js";
const btnTypes = ["去助力", "上火爆区", "点击进入"];
const gameTypes = [
  {
    name: "三端",
    bgC: "#2094ff",
  },
  {
    name: "源码",
    bgC: "#00D2B9",
  },
  {
    name: "手游",
    bgC: "rgba(0,210,185,1)",
  },
  {
    name: "端游",
    bgC: "#FFA49E",
  },
];

export default {
  data() {
    let conBoxStyle = {
      bgC: "#FFFFFF",
      color: "#000000",
    };
    let tableType = "";
    switch (this.typeName) {
      case "精品置顶":
        tableType = "table-1";
        break;
      case "今日开服":
        tableType = "table-2";
        break;
      case "已开推荐":
        tableType = "table-3";
        break;
      case "开服预告":
        tableType = "table-3";
        break;
        case "其他游戏":
        tableType = "table-4";
        break;
      default:
        break;
    }
    return {
      typeName: "其他游戏",
      isAccessAll: false,
      isExecute: false,
      gameTypeId: null,
      tableType,
      multiple: 1,
      id: 0,
      conBoxStyle,
      isOpenHot: 1,
      hidDialog: null,
      loginOk2: 0,
      list: [
        {
          id: 1,
          serverName: '春秋千年',
          domainName: 'https://fanyi.baidu.com/',
          link: 'https://fanyi.baidu.com/'
        },
        {
          id: 2,
          serverName: '春秋千年',
          domainName: 'https://fanyi.baidu.com/',
          link: 'https://fanyi.baidu.com/'
        },
        {
          id: 3,
          serverName: '春秋千年',
          domainName: 'https://fanyi.baidu.com/',
          link: 'https://fanyi.baidu.com/'
        }
      ],
      bigtable: [
        {
          id: 1,
          serverName: '春秋千年',
          domainName: 'https://fanyi.baidu.com/',
          link: 'https://fanyi.baidu.com/'
        },
        {
          id: 2,
          serverName: '春秋千年',
          domainName: 'https://fanyi.baidu.com/',
          link: 'https://fanyi.baidu.com/'
        },
        {
          id: 3,
          serverName: '春秋千年',
          domainName: 'https://fanyi.baidu.com/',
          link: 'https://fanyi.baidu.com/'
        }
      ],

      // 分页
      current: 1, // 当前页
      size: Math.ceil(document.documentElement.clientHeight / 43) || 20, // 每页总条数
    };
  },
  components: {
    // Table: (resolve) => {
    //   require(["@/components/Table/index.vue"], resolve);
    // },
  },
  created() {
    this.getList();
    // 拿到今日开服的最新10条插入
    // this.bigtable = this.handleList(this.list);
    // this.bigtable = this.list;
  },
  mounted() { },

  methods: {
    goHelp(v, url, id) {
      window.open(url);
      this.adHitCount(id);
    },
    // 广告点击量统计
    async adHitCount(id) {
      const res = await api.adHitCount({ id })
    },
    getList() {
      api.getEmpowerAd().then((res) => {
        // console.log(res,'res')
        
        // 切割只剩域名
             res.forEach(e => {
            e.webUrl =  e.webUrl.split("://")[1].split(":")[0].split("/")[0]
          });
        this.list = res
        // if (res) {
        //   res.forEach(e => {
        //     e.imgUrl = this.$httpUrl + e.imgUrl
        //   });
        //   this.list = res
        // }
      });
    },
    // 对免费广告数据处理

    // 对列表数据进行处理


    handleList(val) {
      let list_ = val.filter((item) => item); // 去除手游类型;

      list_ = this.handleExtendList(list_);
      list_.map((item, index) => {
        // 游戏标签
        item.gameTypes = {
          ...gameTypes[item.gameTypeId - 1],
          // color: item.labelColor,
          // bgC: item.labelBackgroundColor || gameTypes[item.gameTypeId - 1].bgC,
        };

        // 按钮
        item.btnType = btnTypes[2];
        if (this.tableType == "table-1") {
          item.btnType = btnTypes[0];
        }
        if (this.tableType == "table-2" && item.hotArea === 0) {
          item.btnType = btnTypes[1];
        }
        if (this.tableType == "table-1") {
          const time = item.openTime && item.openTime.split(" ");
          item.openTime = time?.[0];
        }
        return item;
      });
      return list_;
    },
    handleExtendList(list) {
      let arr = [];
      // 扩展条数
      list.map((item, index) => {
        if (item.contentType == undefined || item.contentType == "") {
          let len = item.hotArea ? 1 : item.multipleTotal;
          // this.tableType == "table-1" ? item.topNumber : item.multipleTotal;
          Array.from({ length: len || 1 }, (_, idx) => {
            arr.push({
              ...this.handleTabItemStyle(item, index, idx),
              ...item,
            });
          });
        } else {
          arr.push({
            ...item,
          });
        }
      });
      return arr;
    },
    handleTabItemStyle(item, index, idx) {
      let classObj = {
        row: "",
      };
      let skin = {};
      if (idx > item.topNumber - 1) {
        classObj.row = classObj.row + " showJP";
      }

      if (this.tableType == "table-2" && item.hotArea === 0) {
        classObj.row = classObj.row + " chuanggui";
      }
      if (idx <= item.skinNumber - 1 && item.hotArea !== 0) {
        skin = {
          bgC: item.backgroundColor || "#fafabb", //  item.backgroundColor ,
          color: item.fontColor || "#ff6d1a", //item.fontColor,
        };
      }
      return {
        skin,
        classObj,
      };
    },
    // 整行点击跳转链接
    lineClick(values, url, id) {
      this.adHitCount(id)
      window.open(url);
    },

    hiddenDialog() {
      this.$emit("getList", true);
    },
  },
  watch: {
    list(val) {
      this.bigtable = this.handleList(val);
    },
  },
};
</script>

<style lang="scss" scoped>
.game-type {
  position: absolute;
  left: -2px;
}

@import "@/common/tablemodule.scss";

.app-content {
  min-height: 424px;
  width: 890px;
  margin: 0 auto;
  padding-bottom: 0;
  background-color: #fff;

  .top {
    width: 100%;

    display: flex;
    justify-content: flex-start;
    background: #F2F2F2;
    border:1px solid #D7D7D7; 
    .row-item {

      line-height:41px;
      color: #404040;
          // font-weight: 550;
          font-size: 16px;
      letter-spacing: 3px;
      height:41px;
    }
  }

  .eachLine {
    .row {
      &:hover {
        background: #fcfcfc !important;
        cursor: pointer;
      }
    }
  }

  .conten {
    background-color: #fff;
    text-align: left;
    font-weight: 500;
    letter-spacing: 1px;
    color: #404040;
    border: 1px solid rgba(232, 232, 237, 0.801);
    .row {
      display: flex;
      padding: 2px 12px 0px  22px;
      width: 100%;
      border-bottom: 1px solid rgba(232, 232, 237, 0.801);
      height: 33px;
      justify-content: space-between;
      position: relative;
    }

    .item-pic {
      width: 100%;
      padding: 15px 23px 0;
      background-color: #fff;
      margin-top: -2px;
      margin-bottom: 15px;
      position: relative;

      a img {
        width: 1053px;
        height: 86px;
      }
    }

    .tag-box {
      width: 100%;
      height: 0px;
      position: relative;

      .tag1 {
        width: 41px;
        height: 106px;
        left: -50px;
        position: absolute;
        background-image: url(@/assets/images/火爆.png);
        background-size: contain;
        background-repeat: no-repeat;
      }

      .tag2 {
        width: 41px;
        height: 106px;
        left: -50px;
        position: absolute;
        background-image: url(@/assets/images/free.png);
        background-size: contain;
        background-repeat: no-repeat;
      }
    }
  }

  .row-item {
    display: flex;
    align-items: center;
    white-space: nowrap;
    flex-shrink: 0;
    position: relative;
    padding-left: 3px;

    span {
      flex-shrink: 0;
      max-width: 196px;
      overflow: hidden;
    }
  }
}

.table-1 {
  .conten {
    .row {}
  }

  .row-item {
    font-weight: normal;

    span {
      max-width: 204px;
    }

    .today {
      display: none;
    }

    .jp {
      position: absolute;
      left: -30px;
    }

    .game-type {
      position: absolute;
      left: -40px;
    }

    .number {
      margin-left: 5px;
    }

    .hot-spot {
      width: 95px;

      span {
        width: 72px;
      }
    }
  }

  .row-item1 {
    width: 120px;
    margin-left: 30px;
  }

  .row-item2 {
    width: 170px;
    text-indent: 22px;

    span {
      width: 5;
    }
  }

  .row-item3 {
    width: 190px;
  }

  .row-item4 {
    width: 190px;
  }

  .row-item5 {
    width: 180px;
  }
}

.table-2 {
  padding-bottom: 70px;

  .conten {
    .row {
      font-weight: normal !important;
    }
  }

  .row-item {
    span {
      max-width: 04px;
    }

    .today {
      position: absolute;
      left: -30px;
    }

    .jp {
      margin: 0 5px;
    }

    .game-type {
      position: absolute;
      left: -40px;
    }

    .hot-spot {
      display: none;
    }
  }

  .row-item1 {
    width: 150px;
    margin-left: 30px;
  }

  .row-item2 {
    width: 85px;
    justify-content: center;
    text-indent: -70px;
  }

  .row-item3 {
    width: 190px;
    margin-left: -28px;
    // text-indent: -60px;
  }

  .row-item4 {
    width: 190px;
  }

  .row-item5 {
    width: 90px;
  }

  .top .row-item5 {
    margin-right: -9px;
    margin-left: 7px;
  }
}

.table-3 {
  padding-bottom: 70px;

  .conten {
    .row {
      // font-size: 14px;
    }
  }

  .row-item {
    .today {
      display: none;
    }

    .jp {
      position: absolute;
      left: -30px;
    }

    .game-type {
      position: absolute;
      left: -40px;
    }

    .hot-spot {
      display: none;
    }
  }

  .row-item1 {
    width: 120px;
    margin-left: 30px;
  }

  .row-item2 {
    width: 170px;
  }

  .row-item3 {
    width: 190px;
  }

  .row-item4 {
    width: 190px;
  }

  .row-item5 {
    width: 90px;
  }

  .top .row-item5 {
    margin-right: -9px;
    margin-left: 7px;
  }
}

.table-4 {
  padding-bottom: 70px;

  .conten {
    .row {
      font-size: 14px;
    }
  }

  .row-item {
    span {
      max-width: 180px;
    }

    .today {
      display: none;
    }

    .jp {
      position: absolute;
      left: -30px;
    }

    .game-type {
      position: absolute;
      left: -40px;
    }
  }

  .row-item1 {
    width: 120px;
    margin-left: 30px;
  }

  .row-item2 {
    width: 170px;
  }

  .row-item3 {
    width: 190px;
  }

  .row-item4 {
 
    width: 190px;
    border:1px solid red;
  }

  .row-item5 {
    border:1px solid red;
    width: 175px;
  }
}

// 常规推荐样式
.app-content .conten .chuanggui {
  color: #a3a1a1;
  font-size: 14px;

  .row-item {
    span {
      max-width: 180px;
    }
  }

  .today {
    color: #777777;
    border-color: #a3a1a1;
  }

  .number {
    display: none;
  }

  .jp {
    display: none;
  }

  .game-type {
    display: none;
  }

  .hot-spot {
    display: none;
  }
}

// 皮肤
// .app-content .conten .skin1 {
//   color: #ff6d1a;
//   background-color: #fafabb;
//   // font-size: 14px;
//   .hot-spot {
//     color: #ff6d1a;
//   }
// }

// 精品图标
.app-content .conten .showJP {
  .jp {
    display: none;
  }
}
</style>
  
