
<template>
  <div class="" ref="mygood_lazy" style="background-color: #fff;width: 890px;
  margin-top: 13px;
   ">

    <!-- margin-top: 13px; -->
    <!-- <div class="table-name">
      <h2>精品置顶</h2>
    </div> -->
    <div style="width: 890px; margin: 0 auto">
      <Table @getList="getList" :alltable.sync="list" :pic="adimg4" typeName="精品置顶" :tabStyle="{}" :show="false"
        :show2="true" :imgShow="true"></Table>
      <!-- <a :href="pic.webUrl" target="_blank"> -->
      <img :src="pic.imgUrl" @click="gopad(pic)" style="margin-left: 0px;width: 890px; cursor:pointer;
    height: 110px;">
      <!-- </a> -->
    </div>
  </div>
</template>


<script>
import api from "@/api/api.js";
import Table from "@/components/Table/index.vue"
let mock = require("./mock.json");
export default {
  props: ["pic"],
  data() {
    return {
      adimg4: [],
      isAccessAll: false,
      isExecute: false,
      list: [
        // ...mock
      ],
      list2: [],
      // 分页
      current: 1, // 当前页
      size: Math.ceil(document.documentElement.clientHeight / 43) || 20, // 每页总条数
    };
  },
  components: {
    Table
  },
  created() {
    // this.$emit('getImg')

    this.getList();
    // this.getTopNotice();
  },
  mounted() {
    // this.getImg()

    // this.$emit('getImg')
  },
  methods: {
    gopad(item) {
      if (item.isSubPage == 1) {
        // 1二级页面 2链接
        // 路由跳转
        this.$router.push({
          name: "AdDetails", params: {
            id: item.id,
          },
        });
      } else {
        window.open(item.webUrl);
      }
      // console.log(item.id, '热词做判断去二级页面还是链接', item.type);

    },
    goNotice(val) {
      // console.log(val, 'val')
      this.$router.push({
        name: "Notcontent",
        params: {
          id: val,
        },
      }); //点击进入详情页面
    },
    getTopNotice() {
      api.getTopNotice().then((res) => {
        // console.log(res,'置顶')
        this.list2 = res;
        res.forEach(e => {
          e.textContent = e.textContent.replaceAll('replace/media', this.$httpUrl + '/media')
          e.textContent = e.textContent.replaceAll('<img src=', '<img style="width:100%" src=')
        });
        // if (res) {
        //   this.list = res.map((item, index) => {
        //     const text_ =
        //       item &&
        //       item.textContent.replace(
        //         /<img [^>]*src=['"]([^'"]+)[^>]*>/gi,
        //         function (match, capture) {
        //           //capture,返回每个匹配的字符串
        //           const src = capture.split("/");
        //           src[0] = this.$  ;
        //           const newStr = '<img src="' + src.join("/") + '" alt="" />';
        //           return newStr;
        //         }
        //       );
        //     item.textContent = text_;
        //     console.log(item,'所有公告')
        //     console.log(item.textContent,'dddd')
        //     return item;
        //     // console.lof(item,'dddss')
        //   });
        //      console.log(this.list,'拿到的公告')
        // }

      });
    },
    gont() {
      const routeOne = this.$router.resolve({
        path: "/NoticeCenter",
      });
      window.open(routeOne.href, "_blank");
    },

    async getList() {
      if (this.isAccessAll) return true;
      try {
        let data = {
          gameTypeId: null,
          current: this.current,
          size: this.size,
          openStatus: null,
        };
        const result = await api.getHomeOpen();
        this.list.push(...this.handleListItem(result));
        this.isAccessAll = true;
        return false;
      } catch (error) {
        console.error(error,'精品列表');
        return false;
      }
    },
    // 对列表数据进行处理
    handleListItem(array = []) {
      return array.map((item) => {
        const date = new Date(item.openTime).getTime();
        let openType = item.openTime.substring(0, 16);
        // if(new Date().getTime()>date) openType = "已开服";
        // if (item.openTime === "1000-01-01 00:00:00") openType = "已开服";
        // if (item.openTime === "3000-01-01 00:00:00") openType = "敬请期待";
        if (item.gameTypeId == 1) {
          item.gameTypeId = item.gameTypeId + 3
        } else if (item.gameTypeId == 2) {
          item.gameTypeId = item.gameTypeId + 0
        } else if (item.gameTypeId == 3) {
          item.gameTypeId = 3
        } else if (item.gameTypeId == 4) {
          item.gameTypeId = 1
        }
        return {
          ...item,
          openTime: openType,
        };
      });
    },
  },
  watch: {
    // pic: {
    //   handler(newval) {
    //     if (newval.length == 0) {
    //       setTimeout(() => {
    //         this.$emit('getImg')
    //       }, 1500);
    //     }
    //   }
    // }
  }
};
</script>

<style  lang="scss" scoped>
@import "@/common/table.scss";
@import "@/common/tablemodule.scss";

::v-deep .el-carousel__indicators {
  display: none !important;
}

#box {
  // background: #fffaee;
  // margin-left: 30px;
  width: 1200px;
  height: 46px;
  margin-top: 13px;
  // font-size:20px;
  position: relative;
  // margin-bottom: 20px;
  // background: #fffaee;
  // background: white;

  .paimgbox {
    margin-left: 25px;
    width: 21px;
    height: 20px;
    margin-top: 16px;
    /* background: #FFFAEE; */
    font-size: 14px;
    margin-right: 5px;
  }

  .marquee-box {

    scrollbar-width: none;
    /* 取消火狐自带滚动条 */
    position: relative;
    width: 100%;
    height: 100%;
    overflow: auto;
    // background: white;

    // background: #fffaee;
    .pWidth {
      height: 46px;


      margin: 0;
      line-height: 51px;
      padding-left: 13px;
      display: block;
      word-break: keep-all;
      white-space: nowrap;
      overflow: hidden;
      font-weight: 600;
      color: black;
      // color: #606266;
      font-size: 20px;
    }
  }
}

.table-name {
  h2 {
    font-size: 20px;
  }
}

.secbarbox {
  font-size: 15px;
  margin-left: 510px;

  div {
    border-right: 1px solid #909399;
  }
}

.secbarbox div:last-child {
  border: none;
}

.firbarbox div {
  font-size: 15px;
  border-right: 1px solid #909399;
}

.firbarbox div:last-child {
  border: none;
}

.firbar:hover {
  color: #404040 !important;
  font-weight: bold;
}

.secbar:hover {
  color: #404040 !important;
  font-weight: bold;
}

.firbar,
.secbar {
  color: #909399;
  cursor: pointer;
}

.firbar div,
.secbar div {
  text-align: center;
  /* background: pink; */
  width: 79px;
}

/* 表格样式 */
</style>

