import http from '../axios/request2.js';

// 请求
export default {
    //--------------post请求---------------
    // 新的接口们
    //今日开服
    getToday(data) {
        return http.post("/advertisements/today", data)
    },
    // 以往广告
    previous(data) {
        return http.post("/advertisements/previous", data)
    },
    // 明日广告
    tomorrow(data) {
        return http.post("/advertisements/tomorrow", data)
    },
    // 获取广告 
    getAd(id) {
        // return http.get(`/article/getById/` + data,)
        return http.get(`/advertisements/getAd/${id}`)
    },
    getWz(id) {
        return http.get(`/article/getById/${id}`)
    },

    // 按栏目取文章 
    // 综合
    getArticleByzh(data) {
        return http.post("/article/getAllArticleByCategoryId/19", data)
    },
    // 新闻
    getArticleByxw(data) {
        return http.post("/article/getAllArticleByCategoryId/21", data)
    },
    // 公告
    getArticleBygg(data) {
        return http.post("/article/getAllArticleByCategoryId/22", data)
    },
    // 活动
    getArticleByhd(data) {
        return http.post("/article/getAllArticleByCategoryId/23", data)
    },
    // 玩家营地
    wjyd(data) {
        return http.post("/article/getAllArticleByCategoryId/26", data)
    },
    // 测评
    yxcp(data) {
        return http.post("/article/getAllArticleByCategoryId/24", data)
    },
    // 技术服务
    jsfw(data) {
        return http.post("/article/getAllArticleByCategoryId/25", data)
    },    
    // 游戏热搜
    yxrs(data) {
        return http.post("/advertisements/adType/14", data)
    },  
    // 广告类
    // 首页图文广告
    twgg(data) {
        return http.post("/advertisements/adType/13", data)
    },
    dlb(data) {
        return http.post("/advertisements/adType/18", data)
    },
    xlb(data) {
        return http.post("/advertisements/adType/17", data)
    },    
    // 横幅 
    hf(data) {
        return http.post("/advertisements/adType/15", data)
    },  
    // 小横幅
    xhf(data) {
        return http.post("/advertisements/adType/27",data)
    }, 
    homeXhf(data) {
        return http.post("/advertisements/adType/16",data)
    },
    // 网站名称
    aboutName(data) {
        return http.post("/article/getAllArticleByCategoryId/1", data)
    },    
    getfriendLink(data) {
        return http.post("/article/getAllArticleByCategoryId/5", data)
    },
    // 今日新区
    jrxq(data) {
        return http.post("/advertisements/new", data)
    },
    // 昨日广告
    zrgg(data) {
        return http.post("/advertisements/Yesterday", data)
    },
    // 获取栏目 
    // getColumn(id) {
    //     return http.get(`/categories/backstage/getById/${id}`)
    // }
    // 根据栏目id取全部文章
    getAllArticleByCategoryId(data){
        return http.post(`/article/getAllArticleByCategoryId/${data.id}`, data); 
    },
    // 根据栏目id取全部文章
    getAdByCategoryId(data){
        return http.post(`/advertisements/adType/${data.id}`, data); 
    }
}