var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"app-content",class:_vm.tableType},[_c('div',{staticClass:"top"},[_c('span',{staticClass:"row-item row-item1",staticStyle:{"margin-right":"64px","width":"122px"}},[_vm._v("服务器名")]),_c('span',{staticClass:"row-item row-item2",staticStyle:{"width":"177px"}},[_vm._v("开测时间")]),_c('span',{staticClass:"row-item row-item3",staticStyle:{"margin-right":"30px","margin-left":"55px"}},[_vm._v("版本简介")]),(_vm.isOpenHot==true)?_c('span',{staticClass:"row-item row-item4",staticStyle:{"margin-left":"34px"}},[_vm._v("助力冲榜")]):_c('span',{staticClass:"row-item row-item4",staticStyle:{"margin-left":"106px"}},[_vm._v("游戏详情")])]),(_vm.alltable.length == 0)?_c('div',{staticClass:"nothingdata"},[_c('img',{attrs:{"src":require("@/assets/images/no result.png"),"alt":""}}),_c('p',[_vm._v("暂无数据~")])]):_vm._e(),_c('div',{staticClass:"conten",style:({
    backgroundColor: _vm.conBoxStyle.bgC,
    color: _vm.conBoxStyle.color,
  })},_vm._l((_vm.bigtable),function(item,index){return _c('div',{key:index,staticClass:"eachLine",on:{"click":function($event){$event.stopPropagation();return _vm.lineClick(item.btnType, item.webUrl, item.id,item.isSubPage)}}},[(!item.contentType)?_c('div',{staticClass:"row",class:item.classObj?.row},[_c('div',{staticClass:"row-item row-item1"},[_c('div',{staticClass:"server-name"},[_vm._v(" "+_vm._s(item.serverName)+" ")]),(item.hotArea != null)?_c('div',{staticClass:"today"},[_vm._v("今")]):_vm._e(),(item.hotArea == null)?_c('div',{staticClass:"today2 flex allcenter"},[_vm._v("今")]):_vm._e(),(item.multipleTotal > 1 && _vm.typeName == '今日开服')?_c('div',{staticClass:"number"},[_vm._v(" x "),_c('span',[_vm._v(" "+_vm._s(item.multipleTotal)+" ")])]):_vm._e(),(item.topNumber > 1 && _vm.typeName == '精品置顶')?_c('div',{staticClass:"number"},[_vm._v(" x "),_c('span',[_vm._v(" "+_vm._s(item.topNumber)+" ")])]):_vm._e(),_c('div',{staticClass:"jp flex allcenter"},[_vm._v("精")])]),_c('div',{staticClass:"row-item row-item2"},[_vm._v(" "+_vm._s(item.openTime)+" ")]),_c('div',{staticClass:"row-item row-item3"},[_c('div',{staticClass:"game-type",style:({
            backgroundColor: item.gameTypes.bgC,
            color: item.gameTypes.color,
          })},[_vm._v(" "+_vm._s(item.gameTypes.name)+" ")]),_c('span',[_vm._v(" "+_vm._s(item.versionDescribe)+" ")])]),_c('div',{staticClass:"row-item row-item4"},[_c('div',{staticClass:"hot-spot"},[(_vm.isOpenHot == true)?_c('img',{staticStyle:{"margin-right":"10px"},attrs:{"src":require("@/assets/images/fire.png"),"alt":""}}):_vm._e(),(_vm.isOpenHot == true)?_c('span',[_vm._v(_vm._s(item.totalSpot || 0))]):_vm._e()]),_c('el-button',{staticClass:"pay",on:{"click":function($event){$event.stopPropagation();return _vm.goHelp(item.btnType, item.webUrl, item.id, item.multipleTotal,item.isSubPage)}}},[_vm._v(" "+_vm._s(item.btnType)+" ")])],1)]):_vm._e(),(item.contentType == 'tag')?_c('div',{staticClass:"tag-box"},[_c('div',{class:item.className})]):_vm._e()])}),0),_c('login',{directives:[{name:"show",rawName:"v-show",value:(_vm.showLogin),expression:"showLogin"}],on:{"hidlogin":_vm.hidlogin,"hidlogin2":_vm.hidlogin2,"loginOk":_vm.loginOk}}),_c('my-dialog',{ref:"myDialog",attrs:{"id":_vm.id,"type":_vm.dialogType,"loginOk":_vm.loginOk2},on:{"hiddenDialog":_vm.hiddenDialog,"closeDialog":function($event){_vm.dialogType = 1}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }