var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"box",staticStyle:{"overflow":"hidden","background-color":"#fff","margin-top":"13px"}},[_c('span',{staticStyle:{"color":"#FF7226","float":"right","margin-bottom":"13px"}},[_vm._v("本区展示截止至2023-11-16")]),_c('div',{staticStyle:{"width":"890px","margin":"0 auto"}},[_c('div',{staticClass:"app-content",class:_vm.tableType},[_vm._m(0),(_vm.list.length == 0)?_c('div',{staticClass:"nothingdata"},[_c('img',{attrs:{"src":require("@/assets/images/no result.png"),"alt":""}}),_c('p',[_vm._v("暂无数据~")])]):_vm._e(),_c('div',{staticClass:"conten"},_vm._l((_vm.bigtable),function(item,index){return _c('div',{key:index,staticClass:"eachLine",on:{"click":function($event){$event.stopPropagation();return _vm.lineClick(item.btnType, item.webUrl, item.id)}}},[(!item.contentType)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"row-item row-item1",staticStyle:{"width":"100px","margin-left":"10px"}},[_c('div',{staticClass:"server-name",staticStyle:{"margin-right":"4px"}},[_vm._v(" "+_vm._s(item.serverName)+" ")])]),_c('div',{staticClass:"row-item row-item2",staticStyle:{"width":"70px"}},[_vm._v(" "+_vm._s(item.webUrl)+" ")]),_c('div',{staticClass:"row-item row-item5",staticStyle:{"width":"180px","padding-left":"96px"}},[_c('el-button',{staticClass:"pay2",on:{"click":function($event){$event.stopPropagation();return _vm.goHelp(
                  item.btnType,
                  item.webUrl,
                  item.id,
                  item.multipleTotal
                )}}},[_vm._v(" 点击进入 ")])],1)]):_vm._e(),(item.contentType == 'pic')?_c('div',{staticClass:"item-pic"},[_c('a',{staticStyle:{"display":"flex"},attrs:{"href":item.webUrl}},[_c('span',{staticClass:"advert-tag"},[_vm._v("广告")]),_c('img',{attrs:{"src":item.imgUrl}})])]):_vm._e(),(item.contentType == 'tag')?_c('div',{staticClass:"tag-box"},[_c('div',{class:item.className})]):_vm._e()])}),0)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"top"},[_c('span',{staticClass:"row-item row-item1",staticStyle:{"width":"346px","margin-left":"30px"}},[_vm._v("服务器名")]),_c('span',{staticClass:"row-item row-item2",staticStyle:{"width":"380px"}},[_vm._v("服务器域名")]),_c('span',{staticClass:"row-item row-item4",staticStyle:{"margin-left":"40px","width":"236px"}},[_vm._v("游戏详情")])])
}]

export { render, staticRenderFns }